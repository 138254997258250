import './index.css';
import NavBar from './components/NavBar/NavBar';
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from './components/Home/Home';
import Footer from './components/Footer/Footer';
import DoctorPage from './components/DoctorPage/DoctorPage';
import React, { useState } from 'react';
import AboutUs from './components/AboutUs/AboutUs';
import Contact from './components/Contact/Contact';
import Specialities from './components/Specialities/Specialities';
import Careers from './components/Careers/Careers';
import Offers from './components/Offers/Offers';
import Privacy from './components/Privacy/Privacy';
import Insurances from './components/Insurances/Insurances';
import FloatingContact from './components/FloatingContact/FloatingContact';
import Education from './components/Education/Educatoin';
import contactMenu from './components/contactMenu/contactMenu';
import { BrowserRouter as Router, Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import ArticlePage from './components/ArticlePage/ArticlePage';
import Appointment from './components/Appointment/Appointment';
import DownloadPdf from './components/DownloadPdf/DownloadPdf';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import ThankYouPage from './components/ThankYouPage/ThankYouPage';
import { useRef } from 'react';
import AllArticles from './components/AllArticles/AllArticles';
import DoctorProfile from './components/DoctorProfile/DoctorProfile';
import SpecialitiesPage from './components/SpecialitiesPage/SpecialitiesPage';
import JsonSchema from './components/JsonSchema/JsonSchema';
import Tourism from './components/Tourism/Tourism';
function App() {
  const [activeService, setActiveService] = useState('uno dos tres quatro chinqo');
  const [activeComponent, setActiveComponent] = useState('home');
  const [speciality, setActiveSpeciality] = useState('');
  const [activeDoctor, setActivedoctor] = useState('');
  const [blog, setActiveBlog] = useState('');
  const [reloadKey, setReloadKey] = useState('');

  const navigate = useNavigate();


  const handleComponentChange = (component, speciality, doctor, blog) => {

    setActiveComponent(component);
    setActiveSpeciality(speciality);
    console.log(speciality)
    console.log(doctor)
    setActivedoctor(doctor);
    console.log(activeDoctor)
    setActiveBlog(blog);
    window.scrollTo(0, 0);
    // window.history.pushState({}, null, `/${component}`);
  };

  const changeService = (s) => {

    console.log(s)
    setActiveService(s)
    navigate("/specialties/" + s);
    setActiveComponent('Speciality');

    window.scrollTo(0, 0)
    // console.log(s)
  }

  const tawkMessengerRef = useRef();

  const handleMinimize = () => {
    tawkMessengerRef.current.minimize();
  };

  const onLoad = () => {
    console.log('onLoad works!');
  };
  return (
    <div>
      <JsonSchema />
      <button onClick={handleMinimize}> Minimize the Chat </button>
      <TawkMessengerReact
        propertyId="661cf3b31ec1082f04e25c6e"
        widgetId="1hrghftb0"
        ref={tawkMessengerRef}
        onLoad={onLoad} />
      <NavBar onComponentChange={handleComponentChange} />
      <Routes>
        <Route path="/" element={<Home onComponentChange={handleComponentChange} changeService={changeService} />} />
        <Route path="/doctors" element={<DoctorPage onComponentChange={handleComponentChange} />} />
        {/* Add more routes for other components */}
        <Route path="/about-us" element={<AboutUs onComponentChange={handleComponentChange} />} />
        <Route path="/medical-tourism" element={<Tourism onComponentChange={handleComponentChange} />} />

        <Route path="/contact-us" element={<Contact onComponentChange={handleComponentChange} />} />
        {/* <Route path="/specialities" element={<Specialities onComponentChange={handleComponentChange} service={activeService} />} /> */}
        <Route path="/careers" element={<Careers onComponentChange={handleComponentChange} />} />
        <Route path="/our-specialties" element={<Offers onComponentChange={handleComponentChange} />} />
        <Route path="/privacy" element={<Privacy onComponentChange={handleComponentChange} />} />
        <Route path="/insurances" element={<Insurances onComponentChange={handleComponentChange} />} />
        <Route path="/education" element={<Education onComponentChange={handleComponentChange} />} />
        <Route path="/blogs/:articleName" element={<ArticlePage onComponentChange={handleComponentChange} blogs={blog} />} />
        <Route path="/pdf" element={<DownloadPdf />} />
        <Route path="/thankyou" element={<ThankYouPage />}/>
        <Route path="/blogs" element={<AllArticles onComponentChange={handleComponentChange}/>}/>
        <Route path="/doctors/:doctor" element={<DoctorProfile props={activeDoctor}/>}/>
        <Route path="/specialties/:specialityName" element={<SpecialitiesPage speciality={speciality} onComponentChange={handleComponentChange}/>}/>

        <Route path="*" element={<Navigate to="/" />} />


      </Routes>
      <Footer onComponentChange={handleComponentChange} />
      <div style={{ float: "right" }}>
        <FloatingContact />
      </div>
    </div>
  );
}

export default App;
