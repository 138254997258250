import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import COSMETICSURGERY from './images/COSMETIC SURGERY.jpg'; // Ensure the path is correct
import PEDIATRICDENTISTRY from './images/PEDIATRIC DENTISTRY.jpg'; // Ensure the path is correct
import FUEHAIRTRANSPLANT from './images/FUE.jpg'; // Ensure the path is correct
import DERMATOLOGYANTIAGING from './images/DERMATOLOGY & ANTI AGING.jpg'; // Ensure the path is correct
import HAIRLOSS from './images/HAIR LOSS.jpg'; // Ensure the path is correct
import MEDICALSPA from './images/MEDICAL SPA.jpg'; // Ensure the path is correct
import WEIGHTLOSSNUTRITION from './images/WEIGHT LOSS & NUTRITION.jpg'; // Ensure the path is correct
import ENDOCRINOLOGY from './images/ENDOCRINOLOGY.jpg'; // Ensure the path is correct
import PSYCHOLOGY from './images/PSYCHOLOGY.jpg'; // Ensure the path is correct
import ORTHOPEDIC from './images/ORTHOPEDIC.jpg'; // Ensure the path is correct
import COSMETICGYNECOLOGY from './images/BANNER.jpg'
import IVTHERAPY from './images/IV THERAPY.jpg'
import DoctorCard from '../DoctorCard/DoctorCard';
import Carousel from 'react-bootstrap/Carousel';
import { Container, Row, Col } from 'react-bootstrap';
import './SpecialitiesPage.css';
import Appointment from '../Appointment/Appointment';
import SuccessModal from '../SuccessModal/SuccessModal';
import { Helmet } from 'react-helmet';
import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';
import before1 from './images/beforeAfter/before1.jpg'
import after1 from './images/beforeAfter/after1.jpg'
import before2 from './images/beforeAfter/before2.jpg'
import after2 from './images/beforeAfter/after2.jpg'
import before3 from './images/beforeAfter/before3.jpg'
import after3 from './images/beforeAfter/after3.jpg'
import before4 from './images/beforeAfter/before4.jpg'
import after4 from './images/beforeAfter/after4.jpg'
function SpecialitiesPage({ speciality, onComponentChange }) {

    const requestRef = useRef();
    const startTimeRef = useRef(Date.now());

    const animate = () => {
        if (Date.now() - startTimeRef.current >= 5000) {
            handleNext()
            startTimeRef.current = Date.now();
        }
        requestRef.current = requestAnimationFrame(animate);
    };

    const [showAppointment, setShowAppointment] = useState(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);

    const handleCloseAppointment = () => setShowAppointment(false);
    const handleShowAppointment = () => setShowAppointment(true);
    function DisplayPopUp() {
        console.log("test")
        setShowSuccessMessage(true)
    }
    function closePopUp() {
        setShowSuccessMessage(false)
    }


    const doctors = [
        {
            id: 1, name: 'Dr. Amr Majzoub', image: '/Doctors/Majzoub.jpeg', speciality: 'Anesthesia',
            languages: ['Arabic', 'English'],
            coreCompetencies: {
                "Anesthesia": [],
                "Critical care medicine": [],
                "Interventional pain management": [],

            },
            nationality: 'Syrian',
            summary: "Dr.Majzoub has 8 years of experience in all aspects of anesthesia (sedation, general anesthesia, and regional anesthesia). Experience in critical care medicine for life-saving and close monitoring of high risk patients. Experience in minimally invasive non-surgical interventions to relieve chronic pain associated with herniated discs or musculoskeletal injuries.",
            qualifications: [
                "Master of Science in Anesthesia, Critical Care, and Pain Management, Damascus University.",
                "Syrian board in anesthesia and reanimation",
            ]
        },
        {
            id: 2, name: 'Dr. Atif Fahim Mina', image: '/Doctors/Fahim.jpeg',
            speciality: 'Anesthesia', languages: ['Arabic', 'English'],
            "coreCompetencies": {
                "Administering general, spinal, epidural, and local anesthesia and sedation for all cases related to general surgery, gynecology and obstetrics, urology, pediatrics, faciomaxillary, ophthalmology, neurology, orthopedics, ENT, endoscopic surgery, plastic surgery, and dental surgery for children and adults.": [],
                "Orders diagnostic tests as needed.": [],
                "Works closely with surgical specialists.": [],
                "Mentors and supervises anesthesiology interns, residents, and fellows.": [],
                "Determines when a patient has recovered from the effects of anesthesia.": [],
                "Creating anesthesia plans for each patient.": [],
                "Communicating with patients and families throughout the entire surgical process, including preparation and recovery.": [],
                "Mentoring medical students and residents on various procedures.": [],
                "Maintaining comprehensive records of medical procedures and patient care.": []
            },
            summary: "Dr.Atif Mina has more than 50 years of experience in all aspects of anesthesiology, mainly in UAE",
            qualifications: [
                "Specialist Anesthetist",
                "Masters of Science Degree in Anesthesia, Cairo University Medical School,Egypt",
                "Diploma in Anesthesia (DA), Cairo University Medical School , Egypt",
                "MB.B.CH.,Ain Shams Medical School, Egypt",
            ]
        },
        {
            id: 3, name: 'Dr. Noor Sheema Ghazi', image: '/Doctors/Ghazi.jpeg', speciality: 'Anesthesia', languages: ['Hindi', 'English', 'Urdu'],
            coreCompetencies: {
                "Regional blocks": [],
                "Labour Analgesia": [],
                "USG guided lines insertion": []
            },
            nationality: 'Indian',
            summary: "",
            qualifications: [
                "Basic Life Support (BLS) and Advanced Cardiac Life Support (ACLS)",
                "Acute Trauma Life Support (ATLS)",
                "Fundamental Critical Care Support (FCCS)",
                "Pain Management (Sir Ganga Ram Hospital, New Delhi)",
                "Member in the Organizing Committee of the Pain Management and Airway National Conference (Delhi chapter).",
                "Member in the Organizing Committee of the Indian Society of Anesthesiologists, Delhi Chapter 2013.",
                "Member of the Emergency team to provide relief for the bomb blast victims in New Delhi, India",
            ]
        },
        {
            id: 5, name: 'Dr. Diyaaldeen Mohammed',
            image: '/Doctors/Diyaaldeen.jpeg',
            speciality: 'General & Bariatric Surgery',
            languages: ['English', 'Arabic'],
            coreCompetencies: {
                "Specialist Obesity , Laparoscopic & General Surgeon": [],
                "Certified in Bariatric Surgery and Revisional Bariatric Surgery, Switzeland": [],
                "USG guided lines insertion": []
            },
            nationality: '',
            summary: "",
            option: "Medical Director",
            qualifications: [
                "More than 17 years of professional experience with 2000+ surgeries.",
            ]
        },
        {
            id: 35, name: 'Ms. Jessica Khalife',
            image: '/Doctors/jessi.png',
            speciality: 'Clinical Dietitian',
            languages: ['Arabic', 'English', 'French'],
            summary: "Licensed Clinical Dietitian with experience excelling in patient education and medical nutritional therapy. Expert in crafting individualized meal plans and enhancing patient outcomes through evidence-based advice and nutrition counseling. Demonstrates exceptional verbal communication and interdisciplinary collaboration, significantly improving nutrition care processes.",
            nationality: 'Lebanese',
            qualifications: [
                "2018 Bachelor of Science, Nutrition And Dietetics , Lebanese University , Lebanon",
                "2020 Master of Science, Clinical And Public Health Nutrition , Lebanese University , Lebanon"
            ],
            "coreCompetencies": {
                "Patient Education": [],
                "Nutrition counseling": [],
                "Medical Nutrition Therapy": [],
                "Individualized support": [],
                "Behavior Change Techniques": [],
                "Dietary planning": [],
                "Nutrition needs assessment": [],
                "Menu development": [],
                "Health Education": [],
                "Cooking Instructions": [],
                "Evidence-based advice": [],
            }

        },
        {
            id: 6, name: 'Dr. Said Hassan', image: '/Doctors/Hassan.jpeg',
            speciality: 'General & Bariatric Surgery', languages: ['English', 'Arabic'],
            coreCompetencies: {
                "Bariatric Surgery": [],
                "Laparoscopic Sleeve Gastrectomy": [],
                "Gastric Bypass": [],
                "Non-Surgical Weight Loss Procedures": [],

            },
            nationality: "Syrian",
            summary: "Syrian Board Certified in General Surgery, Fellowship in laparoscopic surgery (FMAS), Extensive experience in bariatric surgery, including laparoscopic sleeve gastrectomy, gastric bypass, and non-surgical weight loss procedures, Surgeon in the American Academy for Cosmetic Surgery Hospital",
            qualifications: [
                "Bachelor of Medicine and Surgery (MBBS) from Damascus University, Syria",
                "The Head of the Surgery Department at CosmoHealth Medical Centers",
                "Advanced certificate in clinical education from the Royal College of Surgeons in Glasgow",
                "Previously served as an Adjunct Assistant Professor in RAKMHSU (Surgery)",
                "In the final stage of the MSc in Leadership and Innovation in Healthcare"
            ]

        },        {
            id: 7, name: 'Dr. Alexander Bader', image: '/Doctors/Bader.jpeg',
            speciality: 'Cosmetic Gynecology', languages: ['English', 'Greek'],
            "coreCompetencies": {
                "Labiaplasty": [],
                "Laser vaginal rejuvenation": [],
                "Urinary incontinence": [],
                "Vaginal prolapse": [],
                "Vaginoplasty": [],
                "Rectocele": [],
                "Pelvic floor Reconstructive Surgery": [],
                "Female urine Incontinence": [],
                "Cosmetic gynaecology": [],
                "Aesthetic gynaecology": []
            },
            nationality: 'England',
            summary: "Dr Alexander Bader is a London-based internationally renowned specialist OB/GYN and cosmetic gynaecologist who pioneered cosmetic vaginal surgery (CVS) in Europe. Dr Bader is the first doctor on the continent to exclusively practice cosmetic vaginal surgery. He is a member of the American Academy of Cosmetic Surgery (AACS), the American Academy of Cosmetic Gynaecologists (AAOCG), and the International Society of Cosmetogynecology (ISG). He is regularly invited to lecture and teach at international meetings in cosmetic surgery. His exclusive involvement with reconstructive and cosmetic vaginal surgery for the last decade affords him the opportunity to deal with a large volume of interesting and complex cases on a daily basis. Dr Bader has performed more than 3000 cases in his file. Dr Bader frequently conducts hands-on training courses and holds the position of President of The European Society of Aesthetic Gynaecology (ESAG). He leads a large number of enthusiastic doctors from all over the world who want to be positively involved with this innovative field. Dr Bader is the founder of Bader Medical Institute of London. He divides his time between London and Dubai, where he is a visiting surgeon in several prestigious clinics and hospitals.",
            qualifications: [
                "Medical School1996 University of Ioannina",
                "Maternity clinic, General Hospital Athens 'Elenas Venizelou'",
                "Kiel School Of Laparoscopy, Christian-Albrechts Holstein Universitat, Germany",
                "S.C.U Hospital, Los Angeles, California, U.S.A",
                "P. Cosmetogynecology Centre, New Jersey, U.S.A",
                "Diploma, American Academy Of Cosmetic Gynecology (AAOCG)",
                "Qualified in 'Infertility Treatment', University KIEL Germany"
            ]

        },
        {
            id: 8, name: 'Dr. Ayman Helmi',
            image: '/Doctors/Helmi.jpeg',
            speciality: 'Cosmetic & Plastic Surgery',
            languages: ['Arabic', 'English'],
            coreCompetencies: {
                "The Face": [
                    "Face Lift",
                    "Neck Lift",
                    "Eyelids (Blepharoplasty)",
                    "Cheeks Augmentation",
                    "Otoplasty (Ear Reshaping)",
                    "Fat Transfer/Fat Injection",
                    "Injectables",
                    "Fillers",
                    "Botox",
                    "Platelet rich plasma (PRP)",
                    "Nefertiti - neck rejuvenation"
                ],
                "The Body": [
                    "Body Contouring after weight loss",
                    "Liposuction",
                    "Liposculpture",
                    "Arm Lift",
                    "Arm Liposuction",
                    "Abdominoplasty (Tummy Tuck)",
                    "Lipoabdominoplasty",
                    "Buttock Lift",
                    "Buttock Enhancement",
                    "Thigh Lift"
                ],
                "The Breast": [
                    "Breast Lift",
                    "Breast Augmentation",
                    "Breast Reduction",
                    "Breast Reconstruction",
                    "Male Breast Reduction (Gynecomastia)",
                    "Male Breast Lift"
                ],
                "The Lips": [
                    "Augmentation",
                    "Contouring",
                    "Smoothing",
                ],
            },
            nationality: 'Saudi arabia',
            summary: "Plastic Surgeon, Canadian Board Certified, Consultant and Director of Plastic & Burn Surgery Department, Prince Sultan Military Medical City, KSA Director of Postgraduate Medical Education & Scholarships, Riyadh Military Hospital, KSA (Past) Director of Training Plastic Surgery, Riyadh Military, KSA (Past)",
            qualifications: [
                "International Society of Aesthetic Plastic Surgery",
                "Canadian Society of Plastic Surgery"
            ]
        },
        {
            id: 9, name: 'Dr. Abdulaziz Alrasheed',
            image: '/Doctors/Alrasheed.jpeg', speciality: 'Cosmetic & Plastic Surgery',
            languages: ['Arabic', 'English', 'German'],
            "coreCompetencies": {
                "Body lift & contouring after rapid weight-loss surgeries": [],
                "Body lift & contouring after rapid weight-loss surgeries": [],
                "Belt lipectomy & butt lift with self-filling & without a complete wound in the back": [],
                "4D body sculpting procedures & muscle augmentation": [],
                "Arms, buttocks, upper back, abdomen & thighs lift": [],
                "Waist contouring": [],
                "Mommy Makeover Operation": ["Tummy tuck", "Fat reduction", "Butt augmentation", "Breast augmentation"],
                "All types of Liposuction": ["Fat injection and filling Women breast operations (enlarging, reduction & lifting)", "Male breast reduction/enhancement surgery", "Botox & filler injections", "Nose, face & eyelid surgery"]
            },
            nationality: 'Kuwait',
            summary: "More than 18 years of professional experience",
            qualifications: [
                "Consultant – Plastic Surgery",
                "German Board Certified"
            ]
        },
        {
            id: 10,
            name: 'Dr. Ahmad Alaslawi',
            image: '/Doctors/Alaslawi.jpeg',
            speciality: 'Cosmetic & Plastic Surgery',
            languages: ['Arabic', 'English', 'French'],
            "coreCompetencies": {
                "Body contouring": [],
                "Body contouring": [],
                "Liposuction – Liposculpture": [],
                "Arm lift": [],
                "Arm liposuction": [],
                "Abdominoplasty (Tummy tuck)": [],
                "Lipoabdominoplasty": [],
                "Thigh lift": [],
                "Buttock augmentation": [],
                "Buttock fat grafting (Brazilian butt)": [],
                "Eyelid lift (Blepharoplasty)": [],
                "Eyebrow lifting": [],
                "Lip reconstruction or lifting": [],
                "Neck lipoaspiration & neck lift": [],
                "Otoplasty (Ear reshaping)": [],
                "Face lift": [],
                "Rhinoplasty": [],
                "Breast lift & breast augmentation": [],
                "Breast reduction": [],
                "Breast reconstruction": [],
                "Male breast excision (Gynecomastia)": []
            },
            nationality: 'Kuwait',
            summary: "Consultant – Plastic Surgery / French Board Certified ",
            qualifications: [
                "More than 15 years of professional experience",
            ]
        },
        {
            id: 11, name: 'Dr. Ibrahim Ashary', image: '/Doctors/Ashary.jpeg', speciality: 'Cosmetic & Plastic Surgery',
            languages: ['Arabic', 'English'],
            coreCompetencies: {
                "Specialist in Plastic Reconstructive and Hand Surgery": [],
                "Facioplastic Surgery and Laser": [],
            },
            nationality: 'Saudi Arabia',
            summary: "I am Saudi Arab physician. Specialist in Plastic Reconstructive and Hand Surgery since September 2005, with subspecialty in Facioplastic Surgery and Laser.",
            qualifications: [
                "Universitatsklinikum Tubingen Clinic for General Surgery, Prof. Dr. H.C.H.D.",
                "Berufsgenossenschaftliche Unfallklinik Tubingen, Clinic for Trauma, Hand surgery and Reconstructive Surgery, Prof. Dr. Hans- Eberhard Schaller",
                "Universitatsklinikum Tubingen, Clinic for ENT, Prof. Dr. H.P. Zenner",
                "Universitatsklinikum Tubingen Clinic for Dermatology Surgical Department, Prof. Brunninger",
                "Marien Hospital Stuttgart Center for Plastic Surgery and Facioplastic Surgery, Prof. Dr. W. Gubisch",
                "Marien Hospital Stuttgart Clinik for Anasthesiologic and Operative Intensivmedizin (Intensive Medicine) Chefarzt Dr. Med W. Junginger",
                "Caritas- Krankenhaus St. Josef Klinik für Plastische, Hand und wiederherstellungschirurgie (Breast Center) Breast operation and Reconstructive Dr. Marita Eisenmann- Klein",
                "Experience in Saudi Arabia Pre-Plastic Surgery Specialty General Practitioner at Saudi Aramco Health Center. Physician in the Development Program for Saudi Arab General Practitioners at Saudi Aramco’s Dhahran H.C., 29-12-1990 to 31-10-1996. Rotation in Surgical, Pediatrics, General Internal Medicine, OB/Gyne, Emergency Services and OPD Clinics, 2 months in Harvard Medical School Emergency Medicine Course"
            ]
        },
        {
            id: 12, name: 'Dr. Khaled Amer', image: '/Doctors/Amer.jpeg',
            speciality: 'Cosmetic & Plastic Surgery', languages: ['Arabic', 'English'],
            "coreCompetencies": {
                "Body contouring": [],
                "Liposuction – Liposculpture": [],
                "Arm lift": [],
                "Arm liposuction": [],
                "Abdominoplasty (Tummy tuck)": [],
                "Lipoabdominoplasty": [],
                "Thigh lift": [],
                "Buttock augmentation": [],
                "Buttock fat grafting (Brazillian butt)": [],
                "Eyelid lift (Blepharoplasty)": [],
                "Eyebrow lifting": [],
                "Lip reconstruction or lifting": [],
                "Neck lipoaspiration & neck lift": [],
                "Otoplasty (Ear reshaping)": [],
                "Face lift": [],
                "Rhinoplasty": [],
                "Breast lift & breast augmentation": [],
                "Breast reduction": [],
                "Breast reconstruction": [],
                "Male breast excision (Gynecomastia)": []
            },
            nationality: 'Egypt',
            summary: "More than 15 years of professional experience"
            ,
            qualifications: [
                "Specialist Plastic Surgery",
                "Egyptian Board Certified"
            ]
        },
        {
            id: 13, name: 'Dr. Mohan Rangaswamy', image: '/Doctors/Rangaswamy.jpeg'
            , speciality: 'Cosmetic & Plastic Surgery', languages: ['Hindi', 'English', 'Urdu', 'Tamil', 'Malayalam', 'functional Arabic'],
            coreCompetencies: {
                "Facial Cosmetic Surgery": [
                    "Eyebrow lifts",
                    "Eyelid surgery (blepharoplasty)",
                    "Face & Neck lifts",
                    "Face & Neck contouring (Vaser/J Plasma)",
                    "Rhinoplasty (Nose Correction)",
                    "Face fat grafting",
                    "Face implants"
                ],
                "Body Surgeries": [
                    "Body contouring by advanced liposuction",
                    "VASER, Laser, Power liposuction",
                    "Body tightening by Renuvion plasma device (J Plasma)",
                    "Lipoabdominoplasty (No drains)",
                    "Buttock enhancement by fat graft (BBL)",
                    "Calf contouring and augmentation"
                ],
                "Breast Surgery": [
                    "Breast augmentation",
                    "Breast reduction with lift",
                    "Breast lift",
                ],
                "Reconstructive Surgery and Hand": [
                    "Burn related scars and problems",
                    "Scars and deformities after injury, accidents",
                    "Birth defects including cleft, giant nevus, ear deformity, hands, and others",
                    "Breast reconstruction after mastectomy",
                    "All HAND surgeries"
                ],
            },
            nationality: 'Indian',
            summary: "",
            qualifications: [
                "More than 30 years of professional experience.",
            ]
        },

        {
            id: 15, name: 'Dr. Jeehan Abdul Qadir', image: '/Doctors/Qadir.jpeg',
            speciality: 'Dermatology & Anti aging', languages: ['Arabic', 'English'],
            qualifications: [
                "Dr. Jeehan has been awarded with Emirates Business Woman Award & Leadership (2012) by H. H. Sheikh Ahmed Bin Saeed Al Maktoum.",
                "Award of Appreciation from Ministry of Health, Dubai, United Arab Emirates.",
                "Active public member in the Egyptian Society of Women’s Health (ESWH) a nonprofit, nongovernmental organization that promotes and advocates for women’s health in Egypt.",
                "Dr. Jeehan has been awarded with Emirates Women Award (2014), by H. H. Sheikh Ahmed Bin Saeed Al Maktoum.",
                "Dr. Jeehan has been awarded for her active contribution as public speaker in the campaign 'Your Health is our Passion' from the Ministry of Presidential Affairs, Abu Dhabi, United Arab Emirates (2014).",
                "AACSH has been awarded appreciation award from International Society of Hair Restoration Surgery (ISHRS), DHCC, Dubai, United Arab Emirates (March 2018).",
                "Awarded Most Influential Women in Middle East and acclaimed for professional responsibility, International Women’s Day, United Arab Emirates (March 2018."
            ],
            nationality: 'United Arab Emirates',
            summary: "Dr. Jeehan Abdul Qadir is an Emirati woman, married, and the mother of three children. She is the founder of “The American Academy of Cosmetic Surgery Hospital”, the first cosmetic hospital in Dubai HealthCare City and a well-known cosmetic hospital in Dubai. Dr. Qadir received her degree from Dubai Medical College in UAE and worked as a dermatologist in the Department of Health and Medical Services (DOHMS) in Dupal. She received further dermatological training in Egypt and completed postgraduate courses in cosmetic laser technologies at Northwestern Memorial Medical Centre in the U.S. She serves as executive chairperson of \"Peyora,\" one of the largest online retailers of aesthetically pleasing goods.",
            coreCompetencies: {

            },

        },
        {
            id: 16,
            qualifications: [
                "Specialist Dermatologist (MD)",
                "Head of Dermatology & Anti-Aging Department at American Academy of Cosmetic Surgery Hospital",
                "She is certified from the Turkish Board",
                "Turkish Board of Dermato-Venereology",
                "Member of Turkish Medical Association",
                "Member of Turkish Dermato-Venereology Society",
                "International Membership in the American Academy of Cosmetic Surgery",
                "Supervising the American Association aesthetic courses"
            ],
            name: 'Dr. Mine Burcu Hurbas', image: '/Doctors/Hurbas.jpeg', speciality: 'Dermatology & Anti aging', languages: ['Turkish', 'English'],
            coreCompetencies: {
                "Cosmetic Dermatology": [
                    'Face, neck, hands, and body include',
                    'Injectables: Mesotherapy for Anti Aging and Skin rejuvenation (vitamin, minerals, amino acids, hyaluronic acid, growth factor, collagen bio-stimulators, stem cell injections, dermapen, Platelet Rich Plasma Therapy, Exosomes), Botox, Nefertiti neck, microbotox face and neck, Fillers, Chemical Peels, Threads',
                    'Treatment for Excessive Sweating by botulinum toxin injection',
                    'Vascular laser Therapy, Laser Tattoo Removal, Plexr Plus eyelid thinning, fractionation, and microneedling radiofrequency',
                    'Hair treatments includes Growth factor and Stem cell, Exosome treatment, PRP Hair Treatment (Platelet Rich Plasma), Mesotherapy, and Revage Laser Therapy',
                    'Regenera Activa Hair Loss Treatment'
                ],
                "General Dermatology": [
                    'Examination and Treatment of Skin Conditions, Dermatological Diseases',
                    'Electrocauterization',
                    'Dermoscopy',
                    'Management of acute and chronic skin diseases of both pediatric and adult age groups.',
                    'Management of Psoriasis, Vitiligo, Eczema, Acne, Acne scars, Rosacea, Alopecia, skin infections and nail disorders'
                ]
            },
            nationality: 'Turkish',
            summary: "Dr. Burcu Hurbas has over 19 years of experience in all aspects of dermatology, venereology, and aesthetic medicine. She worked in Istanbul and Dubai at AACSH for more than 16 years as a specialist dermatologist with a high interest in cosmetic and aesthetic dermatology. She provides a wide range of services, incorporating modern techniques in cosmetics.",

        },
        {
            id: 17, name: 'Dr. Inam Faiq', image: '/Doctors/Faiq.jpeg',
            speciality: 'Dermatology & Anti aging', languages: ['Arabic', 'English'],
            "coreCompetencies": {
                "Clinical Dermatology & Skin Conditions": [],
                "Clinical Dermatology & Skin Conditions": [],
                "Botox & Fillers": [],
                "Mesotherapy & PRP": [],
                "Skin Rejuvenation & Glow": [],
                "Face Thread Lifting": [],
                "Hyperpigmentation Treatment": [],
                "Acne and Acne Scars Treatment": [],
                "Chemical Peel": [],
                "Laser Skin Rejuvenation Treatment": []
            },
            nationality: 'United Arab Emirates',
            summary: "Dr Inaam Faiq has over 20 years of experience in aesthetic medicine, with over 15 years of practice in Dubai. She is committed to offering personalized care to all her patients and discussing all the possible treatment options.After achieving her degree, she ensures to remain up-to-date with the latest developments in the field, always striving to combine her knowledge with modern technology to enable the best results.She believes there is nothing that can beat natural beauty and with the help of precision medicine, she strives to bring out the best in her patients.",
            qualifications: [
                "MBCHB Mustansiriyah University, Baghdad",
                "Certified by the American Academy of Anti aging Medicine",
                "Certified by the American board in Aesthetic Medicine"
            ]
        },

        {
            id: 21,
            name: 'Dr. Rita Nawar',
            image: '/Doctors/Nawar.jpeg',
            speciality: 'Endocrinology',
            languages: ['Arabic', 'French', 'English'],
            coreCompetencies: {
                "Diabetes Mellitus, prediabetes, and gestational diabetes": [],
                "Obesity, insulin resistance/hyperinsulinemia & metabolic diseases": [],
                "PCOS": [],
                "Thyroid diseases": [],
                "General endocrine diseases": []
            },
            nationality: 'Lebanese',
            spokenLanguages: ['Arabic', 'French', 'English'],
            summary: "After obtaining her medical degree in 1998, Dr. Rita Nawar completed her residency training in internal medicine at the American University of Beirut in Lebanon. Then she undertook a clinical and research fellowship in endocrinology and metabolism there. She pursued further training with a 2-year fellowship in diabetes endocrinology and metabolism at Case Western Reserve University in Cleveland, Ohio, USA. Dr. Rita Nawar was profoundly involved in medical obesity management, prediabetes, and diabetes as a Medical Director and Endocrinologist in Dubai, UAE, from 2007 until 2023. Since 2023, Dr. Nawar has joined the AACSH and continues her practice there. With over 16 years spent treating patients with obesity, diabetes, and metabolic diseases, Dr. Nawar has participated in authoring the Clinical Practice Recommendations for the Management of Obesity in the United Arab Emirates in 2018. She has also participated in several international and national studies about obesity, such as ACTIO-IO and ACTION-UAE. She has also participated in multiple educational activities about obesity on several in-person and virtual platforms. Dr. Nawar is SCOPE certified (World Obesity Federation) for obesity professional education and is also a member of several international societies, including The Obesity Society (TOS), AACE, The Endocrine Society, and ADA. Dr. Nawar has several research projects and publications in the field.",
            qualifications: [
                "MD (Lebanese university- Faculty of Medical Sciences)",
                "SCOPE certified (world obesity Federation)"
            ]
        },
        // { id: 22, name: 'Dr. Haddo Abdullah', image: '/Doctors/Abdullah.jpeg', speciality: 'ENT', languages: ['Arabic', 'English'] },
        {
            id: 27, name: 'Dr. Ahmed Almousa', image: '/Doctors/Almousa.jpg',
            speciality: 'Orthopedics Surgery', languages: ['Arabic', 'English', 'German', 'English'],

            coreCompetencies: {

            },
            nationality: "German",
            summary: "Dr. Ahmed Almousa is an Orthopedic surgeon in Dubai Healthcare City, Dubai and has an experience of 16 years in this field. Dr. Ahmed Almousa practices at American Academy Of Cosmetic Surgery Hospital (AACSH) FZ-LLC in Dubai Healthcare City.",
            "qualifications": [
                "Bachelor of Medicine and Surgery, MBBCh University of Al-Fateh – Faculty of Medicine, Tripoli, Libya",
                "German Approbation",
                "German Board of Orthopaedics and Trauma Surgery",
                "Certificate Foot Surgery (German Society for Foot and Ankle Surgery)",
                "Certificate for Manuell Therapy bei MWE Germany",
                "Physical Therapy und Balneology (European Institute for Physical Therapy und Balneology (EIPB))",
                "Sport Medicine (Damp Academy), Germany (ZTK 01 – 08)",
                "International Business Management Institute (IBMI) (Economics and International Business, Marketing and Communications, Essential Management Skills)"
            ]

        },
        // {
        //     id: 29, name: 'Dr. Nadia Buhannad', image: '/Doctors/Buhannad.jpeg', speciality: 'Psychology',
        //     languages: ['Arabic', 'English'],
        //     coreCompetencies: {
        //         "Acceptance Commitment Therapy (ACT) for Adults and for Adolescents 2023": [],
        //         "Accredited to use Emotional Competence Inventory (ECI)/ EQ for the assessment of emotional intelligence since 2000": [],
        //         "Qualified to use the Myers-Briggs Type Indicator (MBTI-Personality assessment) from USA since 2002": [],
        //         "Certified (Thought Field Therapy) TFT practitioner, London, 2003": [],
        //         "Cases of sexual harassment of children and homosexuality": [],
        //         "Parenting counseling": [],
        //         "Marriage counseling": []
        //     },
        //     nationality: "Emirati, UAE National",
        //     summary: "Dr. Nadia Buhannad has 24 years of experience in providing psychological, educational, and career counseling, mainly in the UAE. She has counseled over 3,000 hours. Some of the cases she follows include phobias and fears (social phobia, fear of flying, panic attacks, etc.), stress and anxiety (public anxiety, test anxiety, personal interviews, anxiety about intimacy at the beginning of marriage, etc.), behavioral problems to cope with (separation, divorce, death of a loved one, failure at work or study, etc.), personal development for individuals in general and for the employee (self-confidence, anxiety, dealing with others, emotional intelligence, etc.), and coaching and training employees to deal with co-workers (individual sessions or group training).",
        //     qualifications: [
        //         "Master of Science in Anesthesia, Critical Care, and Pain Management, Damascus University.",
        //         "Syrian board in anesthesia and reanimation",
        //     ]
        // },
        {
            id: 32,
            name: 'Dr. Hasan Mahfood',
            image: '/Doctors/Hassann.jpeg',
            speciality: 'Cardiologist',
            languages: ['Arabic', 'English'],
            coreCompetencies: {
                "Hypertension": [],
                "Heart failure and Cardiomyopathy": [],
                "Ischemic heart disease": [],
                "Arrhythmia": [],
                "Valvular heart disease": [],
                "Pericardial disease": [],
                "Preventive cardiology": [],
                "Echocardiography": [],
                "Holter": [],
                "Cardiopulmonary Stress test": []
            },
            nationality: 'Syrian',
            summary: "Dr. Hasan Mahfood is a Specialist cardiologist with 7 years’ experience in all aspects of cardiology, especially hypertension management, preventive cardiology, and heart failure.",
            qualifications: [
                "Master Degree in Cardiology from Tishreen University of Syria",
                "Syrian Board of Cardiovascular Medicine",
                "Membership in ESC (European Society of Cardiology)",
                "Membership in ECS (Emirates Cardiac Society)",
                "Membership in EACVI (European Association of Cardiovascular Imaging)",
                "Membership in EAPC (European Association of Preventive Cardiology)",
                "Membership in HFA (Heart Failure Association)"
            ]
        },
        {
            id: 31,
            name: 'Dr. Abdel Hamid Ibrahim',
            image: '/Doctors/Hamid1.png',
            speciality: 'General Practitioner',
            languages: ['Arabic', 'English'],
            coreCompetencies: {
                "emergency Medicine": [],
                "surgery": [],
                "internal Medicine": [],
                "pediatrics": [],
                "clinicalAuditResearch": [],
            },

            nationality: 'Sudanese',
            summary: "Dr. Abdelhamid Abuzied, a general practitioner (GP) with around 6 years of experience in emergency medicine, medicine, pediatrics, surgery, and clinics. Former Clinical Auditor at Emirates Classification Society (TASNEEF), Abu Dhabi. International researcher, former research reviewer at PLM journal, New York, USA, and clinical research reviewer in medical education and curricular development, United Kingdom.",
            qualifications: [
                'Dubai Health Authority (DHA) license',
                'UAE Ministry of Health (MOH) license',
                'Clinical Reviewer at  journal of Health ,Springer Nature, United Kingdom(UK) UAE',
                'Sudan Medical Council Permanent Registration',
                '(Msc) )Master of Health Professions Education, SMSB',
            ]
        },
        
        {
            id: 33, name: 'Dr. Kris Wasilewski', image: '/Doctors/kris.png',
            speciality: 'Consultant Vascular Surgery', languages: ['Russian', 'Polish', "English"],
            coreCompetencies: {
                "Diagnosis and Latest Treatment of Veins and Peripheral Arterial Diseases": [],
                "Latest Less Invasive Methods of Endovenous Ablation By Laser, Rf": [],
                "Venoseal And Foam Sclerotherapy Of Truncal Veins and Varicose Veins": [],
                "Mini Phlebectomy, Surgical Standard Procedures And Electro Procedures": [],
            },
            nationality: 'Poland',
            summary: "Dr. Kris Wasilewski MD, PhD has more than 35 years of professional experience in surgery. He has been trained in Poland, Germany, France and the US. In 1992 he began practicing in Dubai with special interest in vascular surgery. For the last 15 years his major concern is Phlebology- diagnosis and treatment of venous diseases. Dr. Kris has pioneered new sophisticated mini-invasive methods for the treatment of varicose veins in the region, using Laser, Radiofrequency, VenaSeal and ultrasound guided sclerotherapy. He has always introduced the latest and most advanced methods of vein treatments to his practice.",
            qualifications: [
                "MD, PHD- Poland",
                "European Board of Surgery",
                "Full Specialist Registration in the General Medical Council (UK)",
                "Member of American College of Phlebology and European Venous Forum"
            ]
        },
        ,
        {
            id: 99, name: 'Dr. Ahmed Khazbak',
            image: '/Doctors/Khazbak.png',
            speciality: 'Consultant Plastic Surgery',
            languages: ['Arabic', 'English'],
            summary: "Dr. Ahmed Khazbak is an expert consultant plastic surgeon with 20 years of experience in the field. He is dedicated to helping his patients achieve their desired looks and enhancing their life and body form. Highly specialized in Breast surgeries, including breast augmentation, lift, and reduction. Besides Post Bariatric body contouring, including Liposuction, tummy tuck, and different contouring procedures.",
            nationality: 'Egypt',
            qualifications: [
                "Double Board-Certified Plastic Surgeon",
                "More Than 15 years of Professional Experience",
                "Ain Shams University, Post Graduate",
                "Universitat Autonoma de Barcelona, Postgraduate"
            ],
            "coreCompetencies": {
                "Rhinoplasty": [],
                "Mommy Makeover": [],
                "Weight Loss Procedures": [],
                "Brazilian Buttock Lift": [],
                "Gynecomastia Procedure": [],
                "Breast Augmentation": [],
                "Abdominoplasty": [],
                "Liposuction": [],
                "Post Weight Loss Body Contouring": []

            }

        },
        // {
        //     id: 34, name: 'Dr. Martain Loonen', image: '/Doctors/martin1.png', speciality: 'Cosmetic & Plastic Surgery',
        //     languages: ['Dutch', 'English', 'French', 'German'],
        //     coreCompetencies: {
        //         "Liposuction": [],
        //         "Abdominoplasty": [],
        //         "Hair Restoration Surgery and Hair Loss Treatments": [],
        //         "Eyelid Surgery": [],
        //         "Dermatological Surgery": [],
        //         "Breast Augmentation": [],
        //         "Breast Lift and Breast Reduction Surgery": [],
        //         "Arm and Thigh Lift Surgery": [],
        //         "Reconstructive Surgery": [],
        //         "Hand Surgery": []
        //     },
        //     nationality: 'Dutch',
        //     summary: "Dr. Martain Pierre Jean Loonen is an experienced plastic surgeon. He is a consultant in plastic surgery with extensive experience and interest in plastic, reconstructive, cosmetic, hand, and wrist surgery. He has additional expertise in post-bariatric surgery, hair restoration surgery, and burn management. Dr. Loonen is a faculty member for the American Academy of Aesthetic Medicine, providing international courses in aesthetic and hair loss treatments. He is an invited healthcare expert consultant for the Gerson Lehrman Group in New York, specializing in the scientific evaluation and market implementation of pharmaceutical drugs and treatments for the aesthetic and wound care industries. He is a recognized fellow of the Collegium Chirurgicum Plasticum of the Board of Plastic Surgeons of Belgium and holds recognized membership in the Dutch Society of Plastic Surgeons. Dr. Loonen holds a doctoral degree in plastic surgery and has achieved his degree with outstanding honors as the youngest Ph.D. candidate to have attained that distinction at his alma mater, the University of Utrecht in the Netherlands, in collaboration with the University of Louisville, Kentucky. His published work was cited nearly 500 times in the international scientific literature. In addition to his surgical research endeavors, he is an editorial board member of fifteen international journals, including the American Journal of Biomedical Science and Research, the Journal of Advance Cancer Research, Cosmetology and Oro Facial Surgery, the Journal of Aesthetic & Reconstructive Surgery, and the International Journal of Bioscience and Bioengineering. He has extensive experience in medical education in anatomy,clinical surgery, and clinical practice for medical students, physiotherapists, and nurse staff.",
        //     qualifications: [
        //         "Belgian Board Certified Plastic Surgeon",
        //         "Ph.D. in Plastic Surgery (Netherlands)",
        //         "M.D. (Netherlands)"
        //     ]
        // },
        {
            id: 35, name: 'Dr. Ziad Nimer', image: '/Doctors/DrZiad1.png', speciality: 'General Practitioner', languages: ['Arabic', 'English'],
            coreCompetencies: {
                "Plastic Surgery": [],
                "General Surgery": [],
                "Orthopedic Surgery": [],
                "Bariatric Surgery": []
            },
            nationality: "Jordanian",
            summary: "Dr. Ziad Ahmad Nimer graduated from Medical College in Baghdad, Iraq, in the year 2000. He completed his residency and training of 5 years in Jordanian private and public governmental hospitals in 2010 in general surgery. Dr. Nimer practiced as a general surgeon in Jordan until 2016 and later moved to Dubai, where he has been practicing as a general physician/surgeon to date.",
            qualifications: [
                "MD Iraq, 2000",
                "GP, Jordan 2010",
            ]

        },
        {
            id: 34, name: 'Dr. Eman Rabea Kalloub',
            image: '/Doctors/kalloub1.png',
            speciality: 'Specialist Pediatric Surgery',
            languages: ['Arabic', 'English', 'Swedish'],
            coreCompetencies: {
                "Full mouth Rehabilitation under general anesthesia": [],
                "Full teeth treatment under ( Nitrous-Oxide ) sedation": [],
                "Traumatic injury treatment ": [],
                "Pulp treatment for primary and immature permanent teeth with crowns ( and stainless steel crowns)": [],
                "Surgical extractions with space maintainer appliances.": []
            },
            nationality: 'Swedish',
            spokenLanguages: ['Arabic', 'English'],
            summary: "Dr.Eman Rabea Kalloub is a Swedish Doctor who has more than 15 years of experience working in pediatric dentistry in UAE and other countries. She graduated with distinction from Baghdad university/college of dentistry. Then completed her training courses in the pediatric dentistry field in Sweden. She is a member of the American and European Academies of Pediatric Dentistry. She was among the top 20 students during the course of her study. She ranked third among 321 students at her graduation. She was a senior student and a dental instructor in the Department of Oral and Maxillofacial Surgery. Then she finished her specialty in pediatric dentistry.",
            qualifications: [

            ]
        },
        {
            id: 1, name: 'Dr. Mohammadali Bayat Shahbazi',
            image: '/Doctors/Bayat.jpg',
            speciality: 'Specialist Plastic Surgery',
            languages: ['Persian', 'English'],
            summary: "Dr. Mohammad Ali Bayat Shahbazi was born in 1965. He received his MD degree from Tehran University of Medical Sciences in 1989 and completed a residency in general surgery in 1993 at the same university.After spending 7 years in the southern province of Bushehr as a surgeon, he entered Iran University of Medical Sciences to pursue a fellowship in cosmetic plastic and reconstructive surgery. In 2003, Dr. Bayat Shahbazi completed his fellowship as the top-ranked student of the class.Immediately after his fellowship, Dr. Bayat Shahbazi took the national board-certification exam and ranked 2nd to officially become a board-certified plastic surgeon. He later passed advanced courses in cosmetic laser surgery in England and Germany.",
            nationality: 'Iranian',
            qualifications: [
                "MD Tehran University of Medical Sciences",
                "Residency Tehran University of Medical Sciences",
                "Board Certified Plastic Surgeon"
            ],
            "coreCompetencies": {
                "Rhinoplasty": [],
                "Blepharoplasty (Eyelid Surgery)": [],
                "Otoplasty (Ear Surgery)": [],
                "Face, Forehead, and Neck Lift": [],
                "Orthognathic surgery": [],
                "Breast Lift and Breast Implants": [],
                "Abdominoplasty": [],
                "Liposuction/liposculpture": []
            }

        },

        {
            id: 35, name: 'Dr. Tarafa Nieseh', image: '/Doctors/tarafa.png', speciality: 'Specialist Plastic Surgery', languages: ['Arabic', 'English'],
            coreCompetencies: {
                "Botox": [],
                "Fillers": [],
                "Thread Face Lifting": [],
                "Liposuction & Fat Transfer": [],
                "Breast Surgery": []
            },
            nationality: "Syrian",
            summary: "Dr. Tarafa Nieseh is a Plastic Surgeon in Dubai Healthcare City, Dubai and has an experience of 16 years in this field. Dr. Tarafa Nieseh practices at American Academy Of Cosmetic Surgery Hospital (Aacsh) Fz-Llc in Dubai Healthcare City, Dubai. He completed Bachelor Of Medicine from Narimano Medical University in 1993.",
            qualifications: [
                "More Than 16 years of Professional Experience",
                "Bachelor of Medicine and Bachelor of Surgery - Narimano Medical University, Baku",
                "Plastic Surgery - Ministry of Health, Damascus"
            ]

        },
        {
            id: 98, name: 'Dr. Ziad Ibraheem',
            image: '/Doctors/Ibraheem.jpeg',
            speciality: 'Orthopedics Surgery',
            languages: ['Arabic', 'English'],
            summary: "Orthopedic specialist with 13 years of experience. Surgical and conservative treatment for acute and chronic musculoskeletal conditions.",
            nationality: 'Syrian',
            qualifications: [
                "Syrian Board In Orthopedic Surgery Ministry of Health, Syria",
                "More Than 13 years of Professional Experience",

            ],
            "coreCompetencies": {
                "Orthopedic Surgeon": [],


            }

        },
        {
            id: 36, name: 'Dr. Michael salivaras', image: '/Doctors/salivaras.png', speciality: 'Specialist Plastic Surgery', languages: ['Portuguese', 'English', 'Spanish', 'Greek', 'Arabic'],
            coreCompetencies: {
                "INVASIVE, MINIMALLY INVASIVE (Primary, secondary, tertiary):": [],
                "FACE": [
                    "Cervicofacial Lifting, Brow lifting, Endoscopic Forehead/Brow & Middle face lifting",
                    "Blepharoplasty",
                    "Otoplasty",
                    "Rhinoplasty, Septoplasty",
                    "Chin Augmentation",
                    "Buccal fat pad reduction",
                    "Facial Dimples creation",
                    "Facial/Neck fat grafting, liposuction",
                    "Lip Augmentation, Reduction, Lifting"
                ],
                "BREAST": [
                    "Breast Surgery (Augmentation, Mastopexy/Lifting, Reduction)",
                    "Gynecomastia (Male Breast Reduction)",
                    "Male pectoral augmentation",
                    "Ancillary breast reduction",
                    "Nipple areola correction (reduction, augmentation)"
                ],
                "BODY": [
                    "Brachioplasty (arm lifting, reduction, augmentation)",
                    "Thoracoplasty",
                    "Liposuction, Liposculpture (fat grafting), High definition liposculpture (tumescent, energy-assisted: plasma, laser, ultrasound, vibration)",
                    "Abdominoplasty (tummy tuck), Lipoabdominoplasty",
                    "Flankplasty",
                    "Gluteal/Buttocks Lifting, Augmentation, Reduction",
                    "Thigh lifting, reduction, augmentation",
                    "Calf augmentation, reduction",
                    "Upper Body Lifting",
                    "Lower Body Lifting",
                    "Post massive weight loss/bariatric plastic surgery"
                ],
                "NON INVASIVE PROCEDURES": [
                    "Neuromodulators, Fillers, Thread lifting",
                    "Dermabrasion, Peelings, Microneedling, Mesotherapy, Focused Ultrasound therapy/Ultherapy, MiraDry, Cellfina, Micropigmentation, Tattoo removal, Laser Hair removal, Velashape, LPG, Venus, Hydrafacial etc"
                ]
            },
            nationality: "Brazilian",
            summary: "Dr. Salivaras is a Brazilian and European Board-Certified Aesthetic and Reconstructive Plastic Surgeon and is considered one of the foremost international experts in Aesthetic Plastic Surgery. He is a member of the Brazilian, European, Emirates & International Society of Plastic Surgery, the American & International Society of Aesthetic Plastic Surgery, the Alumni Association of Professor Ivo Pitanguy and the Medical Society of the United Kingdom. Dr. Salivaras believes in a comprehensive approach to every patient.  He recognizes that each person requires an individualized treatment plan.  He encourages all patients considering image enhancement procedures to do their homework, ask questions and always insist on the very best.  “It is this unique combination of training, skill, knowledge, and compassion that makes him among the most sought after plastic surgeons” patients testify.",
            qualifications: [
                "Medical degree, National University of Athens",
                "Post Graduate Certificate, Pontifical Catholic University and Carlos Chagas Institute of PostGraduate Medical Studies in Rio de Janeiro, Brazil",
                "Specialist Title of Aesthetic & Reconstructive Plastic Surgeon in 2007 at the Institute Ivo Pitanguy in Rio de Janeiro, Brazil",
                "Sub-specialty training in Sao Paulo, Brazil and at the Southwestern Medical Centre at University of Texas in Dallas"
            ]

        },
        {
            id: 40, name: 'Dr. Andumbar Dashrath Borgaonkar', image: '/Doctors/Borgaonkar.jpg', speciality: 'Specialist Plastic Surgery', languages: ['Hindi', 'English'],
            coreCompetencies: {
                "FUE hair Transplant": [],
                "Reconstructive Surgeries": [],
                "Botox and Fillers": [],
                
            },
            nationality: "Indian",
            summary: "Dynamic plastic surgeon, looking forward to working in a reputed organization, which provides me practical exposure for growth by leveraging my knowledge and skills. Performed more than 1000 FUE Hair Transplants with successful results Also operated multiple cases of Gynaecomastia, Liposuction, Tummy Tuck, Breast implant,Breast reduction, Botox, fillers and various other cosmetic procedures. Operated cases of Congenital malformations, reconstruction surgeries , Facial fractures, A-V fistula (forDialysis).",
            qualifications: [
                "M.B.B.S.: –May 2001 –Jan 2006 - Internship:- Feb2006 - Feb 2007",
                "M.S.Gen.Surgery:- May 2008 - April 2011.M.M.College,Mumbai.",
                "M.Ch.PlasticSurgery:- Aug.2012- July 2015 B.J.MedicalCollege,Pune",
                "B.J.Medical College & Sasson General Hospital, Pune Grant Govt. Medical College & Sir J.J. Group of Hospitals, Mumbai."
            ]

        },
        {
            id: 40, name: 'Dr Mojtaba Malekidelarestaghi', image: '/Doctors/ENT.png', speciality: 'Specialist - Otolaryngologist / ENT', languages: ['Persian', 'English'],
            coreCompetencies: {
                "Rhinoplasty": [
                    "Primary Rhinoplasty",
                    "Revision Rhinoplasty"
                ],
                "Facial Plastic Surgery": [
                    "Endoscopic Forehead Lift",
                    "Face Lift",
                    "Blepharoplasty",
                    "Facial Fat Injection",
                    "Facial Contouring",
                    "Chin Augmentation",
                    "Cheek Augmentation",
                    "Lip Lift",
                    "Otoplasty",
                    "Botulinum Toxin Injection",
                    "Filler Injection"
                ],
                "Facial Reconstructive Surgeries": [
                    "Facial Rehabilitation Surgeries"
                ],
                "Head and Neck Surgeries": [
                    "Thyroidectomy",
                    "Parotidectomy",
                    "Neck Dissection",
                    "Laryngectomy",
                    "Transoral Laryngeal Surgeries",
                    "Submandibular Gland Surgeries",
                    "Oral Cavity Cancer Surgery"
                ],
                "Rhinology Surgeries": [
                    "Endoscopic Sinus Surgeries",
                    "Paranasal Sinus Cancer Surgeries",
                    "Septoplasty",
                    "Turbinoplasty",
                  
                ],
                "Ear Surgeries": [
                    "Endoscopic Tympanoplasty",
                    "Tympanoplasty",
                    "Mastoidectomy",
                    "Stapedotomy",
                  
                ],

            },
            nationality: "Iranian",
            summary: "Board certified of Otorhinolaryngology – Head and neck surgery. Member of Academic Board of Iran University of Medical Sciences. trained great numbers of Iranian otolaryngologists. Qualified in Rhinoplasty, facial plastic surgeries and Head and neck surgeries.",
            qualifications: [
                "Primary Medical Degree, Tehran University of Medical Sciences, Iran",
                "Special degree in Otolaryngology, Iran University of Medical Sciences, Tehran, Iran",
                "Permanent Medical license",
                "Otolaryngology Board Certification",
                "MD License Certification"
            ]

        },
        {
            id: 40, name: 'Dr Naser Mozafari', image: '/Doctors/Naser.png', speciality: 'Specialist Plastic Surgery', languages: ['Persian', 'English'],
            coreCompetencies: {
                "Rhinoplasty": [],
                "Mommy Makeover": [],
                "Weight Loss Procedures": [],
                "Brazilian Buttock Lift": [],
                "Gynecomastia Procedure": [],
                "Breast Augmentation": [],
                "Abdominoplasty": [],
                "Liposuction": [],
                "Post Weight Loss Body Contouring": []


            },
            nationality: "Iranian",
            summary: "Dr. Naser Mozafari has a prestigious background as a plastic surgeon at Medical Universities in Iran, and he holds a professorship degree in plastic surgery. He is very well known in the region for his expertise and experience at Iranian Hospital. Dr Mozafari also served as Director General of Iranian Hospital, Dubai",
            qualifications: [
                "More than 20 years of professional experience",
            ]

        },
        {
            id: 4965, name: 'Dr Paul Asher Omiragi', image: '/Doctors/Omiragi.jpg', speciality: 'General Practitioner', languages: ['English', 'Arabic'],
            coreCompetencies: {
                "Emergency Medicine/Acute Trauma Intervention: ACLS/BLS": [],
                "Clinical Clerking: Examination/History Taking, Prescription, Treatment Planning, Treatment Administration, Immunization": [],
                "General Outpatient Department (GOPD) Consultations/Electronic Medical Records use ": [],
                "Labour Ward: Conducting normal deliveries, episiorrhaphies, induction of labor": [],
                "Surgical Skills: Lipectomy, appendectomy, herniorrhaphy, I&D, wound suturing and dressing, assisted caesarean section": [],
                "ICU Care: Endotracheal tube insertion, NG tube insertion, Use of ICU Monitors": [],
            },
            nationality: "Nigerian",
            summary: "Dedicated and skilled DHA Licensed General Practitioner with over 5 years’ experience (over 12,000 hours), a comprehensive skill and extensive experience in Emergency Medicine, Clinical Clerking, Surgical Skills, and ICU Care. Demonstrates strong interpersonal and communication skills, critical decision-making abilities, and proficiency in Microsoft Office and Electronic Medical Records. Committed to delivering high-quality patient care and contributing to the smooth operation of healthcare facilities.",
            qualifications: [
                "Bachelor of Medicine Bachelor of Surgery (MBBS) | University of Jos | 2016",
                "Advanced Cardiac Life Support | Postgraduate Institute of Medicine (PMI)/NHCPS | 2024",
                "Membership of the Royal College of Emergency Medicine | Royal College of Emergency Medicine | 2024"
            ]

        },





    ];

    const data = {
        'PEDIATRIC DENTISTRY': {
            'Our Pediatric Dental Care Services': [
                ''
            ],
            'Early Orthodontics Assessments:': [
                'Early diagnosis and treatment of alignment and biting disorders can help avoid later, more complicated complications. When appropriate, our pediatric dentists offer early braces and space maintainers as interceptive treatments. They also do comprehensive orthodontic examinations.'
            ],
            'Specialized Care For Children With Special Needs:': [
                'Since each child is different, some need more time and care than others. We have the tools and expertise necessary to treat kids with special needs in the dental chair, making sure they get the kind, considerate care they need in a welcoming setting.'
            ],
            'Sedation And Anesthesia:': [
                'We provide a range of sedative solutions to guarantee a painless and comfortable experience for kids who have severe anxiety or who need major dental work. This covers general anesthesia, oral sedation, and nitrous oxide (laughing gas).'
            ],
            'Full Mouth Transformation Under General Anesthesia:': [
                'We do full mouth transformation procedures under general anesthesia for kids with significant dental needs, extreme anxiety, or unique medical conditions. We are able to take care of all required dental issues in a single, secure, and comfortable session. In order to provide your child with the best possible care and safety, our skilled team closely collaborates with anesthesiologists.'
            ],
            'Advantages of Full Mouth Transformation:': [
            ],
            'Restorative Dentistry:': [
                'Our restorative therapies are intended to address a wide range of dental concerns, from cavities to tooth traumas. Our services include early childhood caries treatment, crowns, and fillings to keep your child\'s teeth strong and healthy.'
            ],
            'Growth And Development Monitoring:': [
                'It is important to track how your child\'s teeth and jaws are developing. Our team regularly monitors these changes, seeing possible problems early and offering advice on teething, the emergence of primary and permanent teeth, and other related topics.'
            ],



        },
        'IV THERAPY': {
            '': [
                'IV therapy, commonly known as intravenous therapy, is the practice of administering nutrients and fluids directly into the bloodstream for prompt absorption and usage by the body. IV therapy is the quickest way for transferring nutrients throughout the body because it bypasses the digestive system and travels straight to the organs, resulting in a 90-100% absorption rate.',
            ],
            'Nicotinamide Adenine Dinucleotide': [
                'NAD+  is an important and one of the most essential molecules "co-enzyme" are found In each living cell in our body. It is involved in cellular metabolism and energy Production. The body requires Nad+ to convert nutrients to energy and to regulate and maintain many cellular processes. NAD+ is the cornerstone of maintaining healthy internal organs and a healthy neurological system.',
            ],
            'Benefits Of Nad+:': [
                'Increase energy ',
                'General wellness DNA repair and repairs damaged cells ',
                'Protects brain cells & improves mental health.',
                'Anti-aging (slows down aging) muscle protection & reduces pain.',
                'Addiction recovery.',
                'Enhances athletic performance and weight loss.',
                'Supports cellular functions and is one of the best anti-oxidants.',
                'Memory/concentration issues'

            ],
            'Our Range Of Specialized IV Drips': [
                'Unleash the power of intravenous nutrition with our IV drip therapy. Our infusions of fluids, vitamins and minerals can revitalize your health, support your immune system, and improve your energy levels.',
            ],
            'Energy Boost & Weight Loss:': [
                'Our carefully crafted IV drip formula includes metabolism-boosting compounds that can help your body burn calories more efficiently. It\'s a natural and effective way to support your weight loss efforts.',
            ],
            'Liver Detox & Cleanse Therapy:': [
                'Our specialized blend of nutrients helps your liver in the detoxification process, eliminating toxins and promoting a healthier internal environment.',
            ],
            'Hair Nail & Skin- Anti-Aging:': [
                'We understand that true beauty comes from within, and our exclusive IV infusion is designed to nourish your body at the cellular level, promoting healthy hair, strong nails, and glowing skin.',
            ],
            'Immune Boost & Multivitamins For Hydration:': [
                'Our specialized blend of immune-boosting ingredients helps fortify your body\'s defenses. Loaded with vitamin C, zinc, and other essential nutrients, V therapy enhances your immune response, keeping you resilient against common illnesses.',
            ],
            'Anti-Stress & Memory Boost:': [
                'Shield your mind from stress and oxidation – embrace the benefits of our Anti-Stress & Anti-Oxidant Memory Boost IV Drip. Unlock your full potential! ',
            ],
            'Skin Fast Glow:': [
                'Indulge in the ultimate beauty boost with our Skin Fast Glow IV Drip, designed to rejuvenate and enhance the natural glow of your skin. This bespoke intravenous therapy combines a powerful blend of nutrients to promote skin health from within, leaving you with a luminous complexion that radiates vitality',
            ],

        },
        'FUE HAIR TRANSPLANT': {
            '': [
                'Research conducted around the world has revealed a connection between genetic factors and hair loss. For many people, a considerable genetic inheritance might result in hair loss. In addition to these factors, those who frequently change up their haircuts and use different shampoos and cosmetics have been shown to negatively affect their hair. Lately, people have noticed that everything is happening at a rapid pace and that everyone is focused on their hectic lives. In these conditions, hair loss is common in a large number of people worldwide.',
            ],
            'Steps:': [
                'STEP 1: Consultation and Treatment Plan',
                'STEP 2: Blood Tests',
                'STEP 3: Hairline Design and Reconstruction',
                'STEP 4: Local Anesthesia',
                'STEP 5: Harvesting of Grafts from Donor Site',
                'STEP 6: Sites Creation',
                'STEP 7: Implantation of the Grafts into the Sites',
                'STEP 8: Inspection of the Implanted Follicles',
                'STEP 9: Dressing',
                'STEP 10: Instructions and Discharge'
            ],
            'What is FUE Hair Transplant?': [
                'Follicular Unit Extraction (FUE) is a state-of-the-art hair transplant technique designed to restore hair density and cover areas of thinning or baldness without leaving a noticeable scar. Unlike traditional methods, FUE involves the extraction of individual hair follicles from the donor area (typically the back of the head) using a precise microsurgical extraction instrument. These follicles are then carefully implanted into the target areas, creating a natural-looking hairline and fuller hair coverage.',
            ],
            'Why Choose FUE Hair Transplant at The American Academy of Cosmetic Surgery Hospital?': [
                'Expert Team of Surgeons: Our hospital takes pride in housing a team of globally accredited cosmetic surgeons who specialize in the latest hair restoration techniques. With years of experience and a commitment to excellence, our surgeons ensure that each FUE hair transplant procedure is tailored to meet the unique needs and aesthetic goals of our clients.',
                ,
                'Cutting-edge Technology: We leverage advanced technology and innovative techniques to enhance the precision and efficiency of the FUE hair transplant process. Our state-of-the-art equipment minimizes trauma to the scalp, accelerates the healing process, and maximizes the survival rate of the transplanted hair follicles, ensuring outstanding results.',
                'Minimally Invasive Procedure: One of the key advantages of FUE hair transplant is its minimally invasive nature. The procedure involves no stitches, staples, or significant scarring, allowing for a quicker and more comfortable recovery. Patients can typically resume their normal activities shortly after the procedure, making it a convenient option for those seeking significant aesthetic improvements with minimal interruption to their daily lives.',
                'Personalized Care and Support: At The American Academy of Cosmetic Surgery Hospital, we understand that every individual\'s journey to hair restoration is unique. From the initial consultation to post-procedure care, our dedicated team provides personalized support, guiding you through each step of the process with compassion and professionalism.',
                'Transform Your Appearance Today: If you\'re grappling with hair loss and seeking a solution that offers natural, long-lasting results, the FUE hair transplant at The American Academy of Cosmetic Surgery Hospital in Dubai may be the perfect option for you. Embrace the opportunity to rejuvenate your appearance and boost your confidence with this advanced cosmetic procedure.',

            ],
        },
        'COSMETIC & PLASTIC SURGERY': {
            '': [
                'Our expert surgeons combine cutting-edge techniques with personalized care to help you achieve your aesthetic goals. We offer a wide range of procedures, from breast augmentation and facelifts to liposuction and rhinoplasty, all performed by highly skilled and experienced surgeons.',
            ],
            'The Face': [
                'Fat Transfer to the face',
                'Face lift',
                'Eyelids (Blepharoplasty)',
                'Brow lift',
                'Neck Lift',
                'Double Chin correction',
                'Rhinoplasty',
                'Cheek Augmentation',
                'Dimples creation',
                'Buccal Fat Removal',
                'Otoplasty (Ear Reshaping)',
                'Dermabrasion',
                'Permanent fillers removal',
                'Mole and cysts removal',
                'Lip lift',
                'Lip reduction',
                'Neck Bands "Platysma” Correction',
                'Stem Cells',
            ],
            'The Body': [
                'Body contouring / Body reshaping',
                'Upper Body Lift',
                'Lower Body Lift',
                'Liposuction',
                'Arm lift',
                'Abdominoplasty (Tummy tuck)',
                'Lipoabdominoplasty (Advanced tummy tuck)',
                'Thigh lift',
                'Fat Hump Removal (Buffalo Hump)',
                'Calf improvement by implant/fat graft',
                'Buttock Augmentation',
                'Gluteoplasty (Butt lift)',
                'Brazilian butt lift',
                'Fat grafting',
                'High definition liposculpture & vaser',
                'J Plasma',
                'Post Bariatric Lift',
                'Mommy Makeover',
            ],
            'The Breast': [
                'Breast augmentation with implants or fat grafting',
                'Breast lift with or without implants',
                'Breast reduction',
                'Breast implants exchange',
                'Nippleplasty',
                'Areola reduction',
                'Breast reconstruction post mastectomy',
                'Male breast reduction (gynecomastia)',
                'Pectoral implants',
            ],
            'Plastic, Reconstructive & Hand Surgery': [],
            'Post-Burn Deformity and Scar Corrections': []
        },
        'DERMATOLOGY & ANTI AGING': {
            '': [
                'Whether you\'re looking to rejuvenate your complexion, reduce the signs of aging, or achieve a flawless glow, we\'re here to guide you on your journey to radiant, youthful skin.',
            ],
            'The Face': [
                'Injectables',
                'HA Fillers',
                'Collagen biostimulators (Sculptra, Radiesse)',
                'Skin Boosters',
                'Profhilo',
                'Profhydro',
                '3D facelift',
                'Mesotherapy (Amber, Aminoacids, Peptides, HA)',
                'Botox',
                'Thread Lift',
                'Texas - jawline contouring',
                'Non-surgical nose reshaping',
                'Platelet-rich plasma therapy (PRP)',
                'Nefertiti - neck rejuvenation',
                'Chemical peels',
                'Snow White treatment for pigmentation',
                'Mona Lisa - smile enhancement',
                'Dermapen skin rejuvenation',
                'Exosomes',
            ],
            'The Body / Skin': [
                'Laser tattoo Removal',
                'Butt Fillers',
            ],
            'General Dermatology': [
                'Examination and treatment of skin conditions',
                'Electrocauterization',
                'Birthmark removal',
                'Treatment of vascularities',
                'Dermoscopy mole examination / excision',
                'Scar treatment',
            ],
            'Anti-aging / Regenetive Medicine': [
                'Stem Cells',
            ],
        },
        'HAIR LOSS': [
            'Hair loss affects millions worldwide, but understanding its causes and treatments can be a game-changer. From stress and genetics to diet and lifestyle, explore the factors contributing to hair loss and discover expert tips for regaining confidence and healthy locks.',
            'Hair loss treatment (Crown on your head)',
            'Mesotherapy for hair',
            'Revage laser therapy',
            'PRP hair treatment (Platelet rich plasma)',
            'Dry or oily hair and scalp',
            'Excessive scalp itching',
            'Damage from coloring, chemicals, or straightening of the hair',
            'Ketox treatment',
            'Rigenera ™ Hair loss treatment',
            'Hair loss treatment "Crown on your head"',
            'Mesotherapy for hair',
            'Revage laser therapy',
            'PRP hair treatment (Platelet rich plasma)',
        ],
        'MEDICAL SPA': {
            '': [
                'Step into relaxation and rejuvenation at our medical spa, where science meets serenity. Indulge in luxurious treatments designed to enhance both your inner wellness and outer beauty. From advanced skin care therapies to stress-relieving massages, our expert team is dedicated to providing personalized care tailored to your unique needs.',
            ],
            'The Face': [
                'HydraFacial MD',
                'Cleopatra facial',
                'Al Shehana signature facial',
                'Medical facial (customized acne treatment)',
                'Red carpet facial',
                'Stamping facial',
                'Derma roller facial',
                'Microdermabrasion',
                'Caci quantum face lift',
                'Ketox hair treatment',
                'Hydra Gold',
                'White Linen Facial',
                'The Signature Facial',
                'Crystal Peel',
                'Exilis Face',
                'Laser hair removal',
                'Laser tattoo removal',
                'Treatment for face rejuvenation',
                'Treatment of spider & varicose veins',
                'Rioblush - carboxy therapy for dark circles and localized fat adiposits',
                'Mesotherapy (Stretch marks, Fat dissolving, cellulite)',
                'Cellina fractionated radiofrequency skin tightening',
                'Treatment of axillary & palms-hyperhidrosis',
            ],
            'The Body': [
                'CoolSculpting non-surgical fat reduction',
                'CACI quantum',
                'Body wrap',
                'Body massage',
                'Lymphatic drainage massage',
                'Skin pigmentation body treatments',
                'Exilis Body',
                'X-WaveTM',
                'Mag Sculpt',
            ],
            'Post Surgical Rehabilitation': [
                'Post liposuction surgery treatment',
                'Post rhinoplasty surgery treatment packages',
                'Post tummy tuck surgery treatment packages',
                'Post body lift surgery treatment Packages',
                'Post augmentation surgery treatment packages',
            ],
        },
        'WEIGHT LOSS & NUTRITION': {
            '': [
                'Our expert team is dedicated to guiding you towards sustainable lifestyle changes, personalized meal plans, and ongoing support to help you achieve your goals. plus, explore cutting-edge options like bariatric surgery procedures for effective, long-term weight loss solutions.',
            ],
            'Weight Loss Procedures': [
                'Gastric sleeve',
                'Gastric balloon',
                'Mini gastric bypass',
                'Classic (Roux en y) gastric bypass',
                'Duodena I switch',
                'Gastric plication',
                'Endoscopic sleeve gastroplasty',
            ],
            'Nutrition': [
                'Nutrition support',
                'Weight management',
                'Diabetes (including gestational diabetes)',
                'Cholesterol and triglyceride lowering',
                'Irritable bowel syndrome (IBS)',
                'Low FODMAP diet for IBS',
            ],
            ' ': [
                'Nutrition support in: oncology, stroke, neuro-rehabilitation, pre- and post-surgery nutrition, maternal diet and nutrition, pre- and post-bariatric surgery, endocrinology disorders of glucose-insulin metabolism and body weight; disorders of blood pressure & lipid metabolism; disorders of bone metabolism & parathyroid glands disorders of thyroid glands (hypophysis, adrenals, ovary and testis glands), diabetes, metabolic syndrome, obesity & insulin resistance, also in pregnancy polycystic ovary syndrome, acne, hirsutism, altered menses and erectile dysfunction thyroid goiter, nodules and thyroiditis, hyper/hypo-thyroids, also in pregnancy osteoporosis, vitamin d and calcium abnormalities, hyper/hypo-parathyroids pituitary, adrenal and gonads disorders idiopathic and hormonal hypertension; hyper-cholesterol Mia / triglyceridemic'
            ]
        },
        'ENDOCRINOLOGY': [
            'Explore the inner workings of glands like the thyroid, adrenal, and pituitary, and discover how they impact your health and well-being. From managing diabetes to addressing hormonal imbalances, endocrinology holds the key to understanding and optimizing your body\'s functions.',
            'Disorders of glucose-insulin Metabolism and body weight',
            'Disorders of blood pressure & lipids metabolism',
            'Disorders of bone metabolism & parathyroid glands',
            'Disorders of thyroid',
            'Disorders of hypophysis, adrenals, ovary and testis glands',
            'Diabetes, metabolic syndrome, obesity & insulin resistance, also in pregnancy',
            'Polycystic Ovary Syndrome, acne, hirsutism, altered menses and erectile dysfunction',
            'Thyroid goiter, nodules and thyroiditis, hyper/hypo-thyroidism, also in pregnancy',
            'Osteoporosis, vitamin D and calcium abnormalities, hyper/hypo-parathyroidism',
            'Pituitary, Adrenals and Gonads Disorders',
            'Idiopathic and hormonal hypertension; hyper-cholesterolemia / triglyceridemia',
        ],
        'COSMETIC GYNECOLOGY': [
            'Every woman deserves to feel confident and comfortable in her own body.We offer a comprehensive range of surgical and non-surgical treatments specifically designed to address the unique needs and concerns of women seeking cosmetic gynecology procedures.Our team of highly skilled and experienced cosmetic gynecologists are dedicated to providing the highest standard of care in a compassionate and supportive environment.',
            'Laser Vaginal Tightening',
            'Laser stress urinary incontinence treatment',
            'Laser vulvar and inner thighs whitening',
            'Vulvar whitening using serums and chemical peelings',
            'Surgical labiaplasty and minor posterior colporraphy',
            'Early detection, prevention and treatment of age-related gynecological conditions (vaginal atrophy)',
            'G spot - O Shot PRP Treatment',
            'Labial Fillers',
        ],
        'PSYCHOLOGY': [
            'We offer personalized counseling and therapy sessions tailored to your unique needs, guiding you on a journey towards self-discovery and growth. Whether you\'re facing challenges with anxiety, depression, or relationship issues, our compassionate approach empowers you to overcome obstacles and thrive. Take the first step towards a happier, more fulfilling life today.',
            'Comprehensive Psychological Assessment',
            'Individual Counseling and Therapy',
            'Stress Management and Coping Skills',
            'Psychological Support for Chronic Illness',
            'Career Counseling and Guidance',
        ],
        "ORTHOPEDIC": {
            '': [
                'Welcome to our comprehensive orthopedic services, where your comfort and mobility are our top priorities. From sports injuries to joint replacements, our team of skilled orthopedic specialists is here to provide personalized care tailored to your unique needs. Whether you\'re recovering from an injury or seeking relief from chronic pain, trust us to help you get back to living your life to the fullest.',
            ],
            "Fracture Management": [
                "Expert management of pelvis, acetabulum, spine, and extremity fractures, including complex and pediatric cases.",
                "Specialized care for open, complex, and intra-articular fractures, and those associated with vascular or neurological complications."
            ],
            "Joint Replacement": [
                "Application of the latest trends in hip, knee, and ankle replacement surgeries.",
                "Comprehensive counseling, advice, and support from our team of orthopedic consultants.",
                "Close follow-up and support from our physiotherapists and occupational therapists for optimal recovery and pain management."
            ],
            "Musculoskeletal Tumor Care": [
                "Management of benign and malignant musculoskeletal tumors across different age groups.",
                "Complete workup, diagnosis, and tailored treatment plans for musculoskeletal malignancies."
            ],
            "Soft Tissue Procedures": [
                "Expert management of tendon releases, nerve surgeries, and bone infections.",
                "Specialized care for all types of tendons, peripheral nerves, and bone-related conditions."
            ],
            "Neurospine Care": [
                "Holistic management of disc prolapse, spine fusion, and spinal deformities.",
                "Specialized care for conditions like spondylolisthesis and kyphoscoliosis."
            ],
            "Foot & Ankle Services": [
                "Management of diabetic and Charcot foot and ankle conditions, including tailored physiotherapy and orthotic support.",
                "Expert care for ligament repair, bunions, deformities, and conditions like plantar fasciitis and flat feet."
            ],
            "Pediatrics and Sports Injury Management": [
                "Specialized care for congenital and developmental extremity deformities in children.",
                "Management of sports injuries, including arthroscopic procedures for shoulder, hip, knee, and ankle conditions."
            ]

        }
    };

    const metas = {
        "FUE HAIR TRANSPLANT": { title: "Hair Transplant Hospital Dubai | FUE Hair Transplant", desc: "FUE hair transplant is a safe and painless procedure. Get rid of baldness with the latest hair transplant technology. " },
        "COSMETIC & PLASTIC SURGERY": { title: "Best Liposuction Hospital | Cosmetic and Plastic Surgery Dubai", desc: "From body contouring  to mommy makeovers, we are providing all types of cosmetic and plastic surgery procedures that best suit your needs." },
        "DERMATOLOGY & ANTI AGING": { tite: "Dermatology and Antiaging Hospital | Botox and Fillers Dubai", desc: "From Botox and Fillers to facial scars removal, discover our highly personalized approach to achieving glowing, younger-looking skin." },
        "HAIR LOSS": { title: "Hair loss affects millions worldwide, but understanding its causes and treatments can be a game-changer. From stress and genetics to diet and lifestyle, explore the factors contributing to hair loss and discover expert tips for regaining confidence and healthy locks.", desc: "At AACSH we are providing non surgical hair loss solutions to enhance your hair growth quickly." },
        "MEDICAL SPA": { title: "Looking For Hydrafacial or Laser Treatment? | AACSH Medical Spa Dubai", desc: "Say No to imperfections, hello to confidence with our cutting-edge aesthetic treatments. Leading aesthetic clinic and medical spa in Dubai. " },
        "WEIGHT LOSS & NUTRITION": { title: " Weight Loss Treatment Dubai| Bariatric Surgery", desc: "AACSH is the best hospital for Surgical and non-surgical weight loss procedures with experienced bariatric surgeons in Dubai. " },
        "ENDOCRINOLOGY": { tite: "Balancing Your Hormones | Endocrinologist Specialist Dubai", desc: "Experienced endocrinologists in Dubai for Diabetes, Thyroid & hormonal disorders. Book your appointment with our endocrinology specialist." },
        "COSMETIC GYNECOLOGY": { title: "Cosmetic and Reconstructive Gynecology In Dubai", desc: "Get expert care by our specialized aesthetic gynecologist. Book your consultation | Cosmetic gynecologist in Dubai" },
        "PSYCHOLOGY": { title: "Mental Health Specialist| Psychologist in Dubai", desc: "Our psychologists in Dubai are here for your support with their professional counseling for stress, anxiety, relationship issues, panic attacks and more." },
        "ORTHOPEDIC": { title: "Specialist Orthopedic Surgeon Dubai | AACSH", desc: "Our surgeons provide compassionate care to treat Injuries, Shoulder instability, Knee Ligament Disorders and Rotator Cuff Problems to help patients get back to their daily activities." },
        "IV THERAPY": { title: "Vitamin Infusion Therapy | IV Therapy Dubai", desc: "AACSH provides IV infusion or IV therapy in Dubai to boost your immunity and enhance your skin glow. " },
        "PEDIATRIC DENTISTRY": { title: "Pediatric Dental Treatment Dubai | Pediatric Dentist Dubai", desc: "Comprehensive dental care is offered by our pediatric dentist in Dubai to newborns, kids, and teenagers, including those with unique medical needs." }

    };

    const [currentIndex, setCurrentIndex] = useState(0);

    const { specialityName } = useParams();
    const [processedData, setProcessedData] = useState(null);
    const [filteredDoctors, setFilteredDoctors] = useState([]);
    const totalSets = Math.ceil(filteredDoctors.length / 3);

    const handlePrevious = () => {
        setCurrentIndex(prevIndex => (prevIndex === 0 ? totalSets - 1 : prevIndex - 1));
    };

    const handleNext = () => {
        setCurrentIndex(prevIndex => (prevIndex === totalSets - 1 ? 0 : prevIndex + 1));
    };



    useEffect(() => {
        const processed = Object.keys(data).reduce((acc, key) => {
            const formattedKey = key.toLowerCase().replace(/&/g, 'and').replace(/\s+/g, '-');
            acc[formattedKey] = { ...data[key], originalKey: key }; // Store the original key
            return acc;
        }, {});
        setProcessedData(processed);

        const filtered = Object.values(doctors).filter(doctor => doctor.speciality.toLowerCase().replace(/&/g, 'and').replace(/\s+/g, '-') == specialityName);
        if (specialityName == "cosmetic-and-plastic-surgery") {
            const additionalDocs = Object.values(doctors).filter(doctor => doctor.speciality == "Specialist Plastic Surgery" || doctor.speciality == "Consultant Plastic Surgery")
            filtered.push(...additionalDocs);  // Use spread operator to concatenate arrays
        }
        if (specialityName == "orthopedic") {
            const additionalDocs = Object.values(doctors).filter(doctor => doctor.speciality == "Orthopedics Surgery")
            filtered.push(...additionalDocs);  // Use spread operator to concatenate arrays
        }
        if (specialityName == "weight-loss-and-nutrition") {
            const additionalDocs = Object.values(doctors).filter(doctor => doctor.speciality == "General & Bariatric Surgery" || doctor.speciality == "Clinical Dietitian")
            filtered.push(...additionalDocs);  // Use spread operator to concatenate arrays
        }
        if (specialityName == "pediatric-dentistry") {
            const additionalDocs = Object.values(doctors).filter(doctor => doctor.speciality == "Specialist Pediatric Surgery")
            filtered.push(...additionalDocs);  // Use spread operator to concatenate arrays
        }
        if (specialityName == "hair-loss") {
            const additionalDocs = Object.values(doctors).filter(doctor => doctor.name == "Dr. Mine Burcu Hurbas" || doctor.name == "Dr. Inam Faiq")
            filtered.push(...additionalDocs);  // Use spread operator to concatenate arrays
        }
        if (specialityName == "iv-therapy") {
            const additionalDocs = Object.values(doctors).filter(doctor => doctor.name == "Dr Paul Asher Omiragi" || doctor.name == "Ms. Jessica Khalife")
            filtered.push(...additionalDocs);  // Use spread operator to concatenate arrays
        }
        setFilteredDoctors(filtered);
        console.log(filtered)




        requestRef.current = requestAnimationFrame(animate);
        return () => cancelAnimationFrame(requestRef.current);
    }, []);  // Empty dependency array ensures this effect runs only once

    if (!processedData) {
        return <div>Loading...</div>;
    }

    // Destructure the object safely with a default value
    const { [specialityName]: specialData = {} } = processedData;
    const { originalKey, ...subcategories } = specialData;

    const capitalizeWords = (str) => {
        return str.replace(/\b\w/g, (char) => char.toUpperCase());
    };


    const capitalizeFirstLetter = (text) => {
        if (!text) return '';
        return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
    };

    const capitalizedKey = capitalizeWords(originalKey.toLowerCase());
    return (
        <>
            <>
                {originalKey === 'WEIGHT LOSS & NUTRITION' && (
                    <Helmet>
                        <title>
                            Weight Loss Treatment Dubai| Bariatric Surgery
                        </title>
                        <meta name="description" content="AACSH is the best hospital for Surgical and non-surgical weight loss procedures with experienced bariatric surgeons in Dubai. " />
                        {/* Add more meta tags if needed */}
                    </Helmet>)}
                {originalKey === 'COSMETIC GYNECOLOGY' && (
                    <Helmet>
                        <title>
                            Cosmetic and Reconstructive Gynecology In Dubai
                        </title>
                        <meta name="description" content="Get expert care by our specialized aesthetic gynecologist. Book your consultation | Cosmetic gynecologist in Dubai" />
                        {/* Add more meta tags if needed */}
                    </Helmet>
                )}
                {originalKey === 'ENDOCRINOLOGY' && (
                    <Helmet>
                        <title>
                            Balancing Your Hormones | Endocrinologist Specialist Dubai
                        </title>
                        <meta name="description" content="Experienced endocrinologists in Dubai for Diabetes, Thyroid & hormonal disorders. Book your appointment with our endocrinology specialist." />
                        {/* Add more meta tags if needed */}
                    </Helmet>
                )}
                {originalKey === 'FUE HAIR TRANSPLANT' && (
                    <Helmet>
                        <title>
                            Hair Transplant Hospital Dubai | FUE Hair Transplant
                        </title>
                        <meta name="description" content="FUE hair transplant is a safe and painless procedure. Get rid of baldness with the latest hair transplant technology. " />
                        {/* Add more meta tags if needed */}
                    </Helmet>
                )}
                {originalKey === 'DERMATOLOGY & ANTI AGING' && (
                    <Helmet>
                        <title>
                            Dermatology and Antiaging Hospital | Botox and Fillers Dubai
                        </title>
                        <meta name="description" content="From Botox and Fillers to facial scars removal, discover our highly personalized approach to achieving glowing, younger-looking skin." />
                        {/* Add more meta tags if needed */}
                    </Helmet>)}
                {originalKey === 'HAIR LOSS' && (
                    <Helmet>
                        <title>
                            Hair Loss and hair Care Treatment Dubai | Hair Loss Solution
                        </title>
                        <meta name="description" content="At AACSH we are providing non surgical hair loss solutions to enhance your hair growth quickly." />
                        {/* Add more meta tags if needed */}
                    </Helmet>
                )}
                {originalKey === 'IV THERAPY' && (
                    <Helmet>
                        <title>
                            Vitamin Infusion Therapy | IV Therapy Dubai
                        </title>
                        <meta name="description" content="AACSH provides IV infusion or IV therapy in Dubai to boost your immunity and enhance your skin glow. " />
                        {/* Add more meta tags if needed */}
                    </Helmet>)}
                {originalKey === 'MEDICAL SPA' && (
                    <Helmet>
                        <title>
                            Looking For Hydrafacial or Laser Treatment? | AACSH Medical Spa Dubai                        </title>
                        <meta name="description" content="Say No to imperfections, hello to confidence with our cutting-edge aesthetic treatments. Leading aesthetic clinic and medical spa in Dubai. " />
                        {/* Add more meta tags if needed */}
                    </Helmet>)}
                {originalKey === 'ORTHOPEDIC' && (
                    <Helmet>
                        <title>
                            Specialist Orthopedic Surgeon Dubai | AACSH
                        </title>
                        <meta name="description" content="Our surgeons provide compassionate care to treat Injuries, Shoulder instability, Knee Ligament Disorders and Rotator Cuff Problems to help patients get back to their daily activities." />
                        {/* Add more meta tags if needed */}
                    </Helmet>)}
                {originalKey === 'PSYCHOLOGY' && (

                    <Helmet>
                        <title>
                            Mental Health Specialist| Psychologist in Dubai
                        </title>
                        <meta name="description" content="Our psychologists in Dubai are here for your support with their professional counseling for stress, anxiety, relationship issues, panic attacks and more." />
                        {/* Add more meta tags if needed */}
                    </Helmet>)}
                {originalKey === 'PEDIATRIC DENTISTRY' && (
                    <Helmet>
                        <title>
                            Pediatric Dental Treatment Dubai | Pediatric Dentist Dubai
                        </title>
                        <meta name="description" content="Comprehensive dental care is offered by our pediatric dentist in Dubai to newborns, kids, and teenagers, including those with unique medical needs." />
                        {/* Add more meta tags if needed */}
                    </Helmet>)}
                {originalKey === 'COSMETIC & PLASTIC SURGERY' && (
                    <Helmet>
                        <title>
                            Best Liposuction Hospital | Cosmetic and Plastic Surgery Dubai
                        </title>
                        <meta name="description" content="From body contouring  to mommy makeovers, we are providing all types of cosmetic and plastic surgery procedures that best suit your needs." />
                        {/* Add more meta tags if needed */}
                    </Helmet>)}
            </>

            <section id="departments" className="departments" style={{ marginTop: "100px" }}>
                <Appointment show={showAppointment} handleClose={handleCloseAppointment} showPopUp={DisplayPopUp} />
                <SuccessModal show={showSuccessMessage} handleClose={closePopUp} />

                <div className="container">
                    <div className="section-title">
                        <h2 style={{ color: "#2c4964" }}>{originalKey}</h2>
                        <>
                            {originalKey === 'WEIGHT LOSS & NUTRITION' && (
                                <img src={WEIGHTLOSSNUTRITION} alt="Weight Loss & Nutrition" style={{ marginLeft: "30px" }} />
                            )}
                            {originalKey === 'COSMETIC GYNECOLOGY' && (
                                <img src={COSMETICGYNECOLOGY} alt="Weight Loss & Nutrition" />
                            )}
                            {originalKey === 'ENDOCRINOLOGY' && (
                                <img src={ENDOCRINOLOGY} alt="Weight Loss & Nutrition" />
                            )}
                            {originalKey === 'FUE HAIR TRANSPLANT' && (
                                <img src={FUEHAIRTRANSPLANT} alt="Weight Loss & Nutrition" style={{ marginLeft: "30px" }} />
                            )}
                            {originalKey === 'DERMATOLOGY & ANTI AGING' && (
                                <img src={DERMATOLOGYANTIAGING} alt="Weight Loss & Nutrition" style={{ marginLeft: "30px" }} />
                            )}
                            {originalKey === 'HAIR LOSS' && (
                                <img src={HAIRLOSS} alt="Weight Loss & Nutrition" />
                            )}
                            {originalKey === 'IV THERAPY' && (
                                <img src={IVTHERAPY} alt="Weight Loss & Nutrition" style={{ marginLeft: "30px" }} />
                            )}
                            {originalKey === 'MEDICAL SPA' && (
                                <img src={MEDICALSPA} alt="Weight Loss & Nutrition" style={{ marginLeft: "30px" }} />
                            )}
                            {originalKey === 'ORTHOPEDIC' && (
                                <img src={ORTHOPEDIC} alt="Weight Loss & Nutrition" style={{ marginLeft: "30px" }} />
                            )}
                            {originalKey === 'PSYCHOLOGY' && (
                                <img src={PSYCHOLOGY} alt="Weight Loss & Nutrition" />
                            )}
                            {originalKey === 'PEDIATRIC DENTISTRY' && (
                                <img src={PEDIATRICDENTISTRY} alt="Weight Loss & Nutrition" style={{ marginLeft: "30px" }} />
                            )}
                            {originalKey === 'COSMETIC & PLASTIC SURGERY' && (
                                <img src={COSMETICSURGERY} alt="Weight Loss & Nutrition" style={{ marginLeft: "30px" }} />
                            )}
                        </>
                    </div>

                    <div className="row gy-4">
                        {Array.isArray(subcategories) ? (
                            <ul>
                                {subcategories.map((item, subIndex) => (
                                    <h3 key={subIndex} style={{ fontFamily: "Raleway, sans-serif" }}>{item}</h3>
                                ))}
                            </ul>
                        ) : (
                            <div>
                                {Object.entries(subcategories).map(([subcategory, procedures], subIndex) => (
                                    <div key={subIndex}>
                                        {Array.isArray(procedures) ? (
                                            <ul>
                                                <h4 style={{ marginTop: "15px" }}>{subcategory}</h4>
                                                {subcategory == 'Advantages of Full Mouth Transformation:' ? (
                                                    // Render specific content if subcategory is 'Advantages of Full Mouth Transformation:'
                                                    <>
                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                            <h3 style={{ marginBottom: "5px", fontSize: "16px", fontWeight: "bold", marginRight: "5px" }}>
                                                                Whole Care in a Single Visit:
                                                            </h3>
                                                            <h3 style={{ marginBottom: "5px", fontSize: "16px" }}>
                                                                All required procedures, including cleanings, crowns, extractions, and fillings, are completed in a single visit.
                                                            </h3>
                                                        </div>
                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                            <h3 style={{ marginBottom: "5px", fontSize: "16px", fontWeight: "bold", marginRight: "5px" }}>
                                                                Painless Experience:
                                                            </h3>
                                                            <h3 style={{ marginBottom: "5px", fontSize: "16px" }}>
                                                                During the process, general anesthesia guarantees your child's total comfort and unconsciousness.
                                                            </h3>
                                                        </div>
                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                            <h3 style={{ marginBottom: "5px", fontSize: "16px", fontWeight: "bold", marginRight: "5px" }}>
                                                                Decreased Anxiety:
                                                            </h3>
                                                            <h3 style={{ marginBottom: "5px", fontSize: "16px" }}>
                                                                By removing the need for repeated visits, parents' and kids' general stress and anxiety are decreased.
                                                            </h3>
                                                        </div>
                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                            <h3 style={{ marginBottom: "5px", fontSize: "16px", fontWeight: "bold", marginRight: "5px" }}>
                                                                Optimal Outcomes:
                                                            </h3>
                                                            <h3 style={{ marginBottom: "5px", fontSize: "16px" }}>
                                                                Let our dental staff focus on achieving the best possible outcomes for your child’s oral health.
                                                            </h3>
                                                        </div>
                                                    </>
                                                ) : subcategory == 'Weight Loss Procedures' ? (
                                                    // Render specific content if subcategory is 'Weight Loss Procedures'
                                                    <>
                                                        <div style={{ marginBottom: "10px" }}>
                                                            <h3 style={{ fontSize: "16px", fontWeight: "bold" }}>Sleeve Gastrectomy:</h3>
                                                            <p style={{ fontSize: "16px" }}>
                                                                Sleeve gastrectomy, also known as gastric sleeve surgery, is a bariatric procedure designed to reduce stomach size, limiting food intake to aid weight loss when traditional methods like diet and exercise have not been effective.
                                                            </p>
                                                        </div>
                                                        <div style={{ marginBottom: "10px" }}>
                                                            <h3 style={{ fontSize: "16px", fontWeight: "bold" }}>Gastric Balloon:</h3>
                                                            <p style={{ fontSize: "16px" }}>
                                                                Gastric balloons offer a non-surgical weight-loss solution for individuals with a body mass index (BMI) of 27 or more who have struggled to lose weight through other methods. The most common type of gastric balloon, such as Orbera, Lax Ball, or Spatz, is placed orally using an endoscope and is typically removed after 6-12 months. This non-invasive method leaves no scars and can result in weight loss of up to three times more than what is achievable through exercise and diet alone.
                                                            </p>
                                                        </div>
                                                        <div style={{ marginBottom: "10px" }}>
                                                            <h3 style={{ fontSize: "16px", fontWeight: "bold" }}>Mini Gastric Bypass:</h3>
                                                            <p style={{ fontSize: "16px" }}>
                                                                A less invasive alternative to traditional gastric bypass, offering significant weight loss and improvement in obesity-related conditions.
                                                            </p>
                                                        </div>
                                                        <div style={{ marginBottom: "10px" }}>
                                                            <h3 style={{ fontSize: "16px", fontWeight: "bold" }}>Classic (Roux-en-Y) Gastric Bypass:</h3>
                                                            <p style={{ fontSize: "16px" }}>
                                                                Roux-en-Y Gastric Bypass is a highly effective weight loss surgery that reshapes the digestive system to promote significant weight reduction. This procedure involves creating a smaller stomach pouch and rerouting the small intestine, leading to reduced food intake and calorie absorption. It is recommended for individuals struggling with obesity and related health issues.
                                                            </p>
                                                        </div>
                                                        <div style={{ marginBottom: "10px" }}>
                                                            <h3 style={{ fontSize: "16px", fontWeight: "bold" }}>Duodenal Switch:</h3>
                                                            <p style={{ fontSize: "16px" }}>
                                                                The duodenal switch is a weight-loss operation that modifies your stomach and your small intestine. It combines a gastrectomy (removal of part of your stomach) with an intestinal bypass, which makes the path your food takes through your intestines shorter. This restricts how much food your stomach can hold, and also how much nutrition your small intestine can absorb from your food. (This makes it a “malabsorptive” procedure). It also reduces the hunger hormones that your stomach and small intestine normally produce.
                                                            </p>
                                                        </div>
                                                        <div style={{ marginBottom: "10px" }}>
                                                            <h3 style={{ fontSize: "16px", fontWeight: "bold" }}>Gastric Plication:</h3>
                                                            <p style={{ fontSize: "16px" }}>
                                                                Gastric plication surgery, also known as Laparoscopic Gastric Greater Curvature Plication or Gastric Imbrication, is a restriction technique that reduces the stomach size by around 75%. The procedure includes “tucking in” the stomach’s exterior curved part, causing the patient to feel full sooner, which translates to them eating less. This method can be utilized as an initial weight-loss procedure or as an option for revision weight loss surgery.
                                                            </p>
                                                        </div>
                                                        <div style={{ marginBottom: "10px" }}>
                                                            <h3 style={{ fontSize: "16px", fontWeight: "bold" }}>Endoscopic Sleeve Gastroplasty:</h3>
                                                            <p style={{ fontSize: "16px" }}>
                                                                Consider Endoscopic Sleeve Gastroplasty (ESG) for safe and effective weight loss without surgery. ESG involves the application of stitches in the stomach to create a narrow tube, reducing its capacity by 60-70%. This non-surgical procedure provides lasting benefits such as weight loss, improved quality of life for gastric ulcer patients, and better diabetes management.
                                                            </p>
                                                        </div>
                                                        <div style={{ marginBottom: "10px" }}>
                                                            <h3 style={{ fontSize: "16px", fontWeight: "bold" }}>Gastric Banding:</h3>
                                                            <p style={{ fontSize: "16px" }}>
                                                                A reversible surgery that involves placing a band around the upper part of the stomach to reduce food intake.
                                                            </p>
                                                        </div>
                                                    </>
                                                ) : (
                                                    procedures.map((procedure, procedureIndex) => (
                                                        <h3 key={procedureIndex} style={{ marginBottom: "5px", fontSize: "16px" }}>
                                                            {capitalizeFirstLetter(procedure)}
                                                        </h3>
                                                    ))
                                                )}
                                            </ul>
                                        ) : (
                                            <p style={{ fontFamily: "Raleway, sans-serif" }}>{capitalizeFirstLetter(procedures)}</p>
                                        )}
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                    {capitalizedKey == 'Pediatric Dentistry' ? (
                        <section id="doctors" className="doctors">
                            <div className="container">
                                <div className="section-title">
                                    <h2 style={{ color: "#2c4964" }}>Before and after treatment</h2>
                                </div>
                                <div className="row justify-content-center">
                                    <div className='col-lg-6'>
                                        <CardGroup>
                                            <Card style={{ textAlign: "center", border: "none", marginRight: "10px" }}>
                                                <Card.Img variant="top" src={before1} style={{ borderRadius: "10px" }} />
                                                <Card.Body>
                                                    <Card.Title>Before</Card.Title>

                                                </Card.Body>

                                            </Card>
                                            <Card style={{ textAlign: "center", border: "none" }}>
                                                <Card.Img variant="top" src={after1} style={{ borderRadius: "10px" }} />
                                                <Card.Body>
                                                    <Card.Title>After</Card.Title>

                                                </Card.Body>

                                            </Card>

                                        </CardGroup>
                                    </div>
                                    <div className='col-lg-6'>
                                        <CardGroup>
                                            <Card style={{ textAlign: "center", border: "none", marginRight: "10px" }}>
                                                <Card.Img variant="top" src={before2} style={{ borderRadius: "10px" }} />
                                                <Card.Body>
                                                    <Card.Title>Before</Card.Title>

                                                </Card.Body>

                                            </Card>
                                            <Card style={{ textAlign: "center", border: "none" }}>
                                                <Card.Img variant="top" src={after2} style={{ borderRadius: "10px" }} />
                                                <Card.Body>
                                                    <Card.Title>After</Card.Title>

                                                </Card.Body>

                                            </Card>

                                        </CardGroup>
                                    </div>
                                    <div className='col-lg-6'>
                                        <CardGroup>
                                            <Card style={{ textAlign: "center", border: "none", marginRight: "10px" }}>
                                                <Card.Img variant="top" src={before3} style={{ borderRadius: "10px" }} />
                                                <Card.Body>
                                                    <Card.Title>Before</Card.Title>

                                                </Card.Body>

                                            </Card>
                                            <Card style={{ textAlign: "center", border: "none" }}>
                                                <Card.Img variant="top" src={after3} style={{ borderRadius: "10px" }} />
                                                <Card.Body>
                                                    <Card.Title>After</Card.Title>

                                                </Card.Body>

                                            </Card>

                                        </CardGroup>
                                    </div>
                                    <div className='col-lg-6'>
                                        <CardGroup>
                                            <Card style={{ textAlign: "center", border: "none", marginRight: "10px" }}>
                                                <Card.Img variant="top" src={before4} style={{ borderRadius: "10px" }} />
                                                <Card.Body>
                                                    <Card.Title>Before</Card.Title>

                                                </Card.Body>

                                            </Card>
                                            <Card style={{ textAlign: "center", border: "none" }}>
                                                <Card.Img variant="top" src={after4} style={{ borderRadius: "10px" }} />
                                                <Card.Body>
                                                    <Card.Title>After</Card.Title>

                                                </Card.Body>

                                            </Card>

                                        </CardGroup>
                                    </div>

                                </div>
                            </div>
                        </section>
                    ) : null}
                    <div style={{ marginTop: "50px", alignItems: "center", display: "flex", }}>
                        <a className="appointment-btn" onClick={handleShowAppointment} style={{ textDecoration: "none", marginRight: "auto", marginLeft: "auto" }} href="#appointment">Book Now</a>

                    </div>
                    {filteredDoctors.length > 0 && (

                        <section id="doctors" className="doctors">
                            <div className="container">
                                <div className="section-title">
                                    <h2 style={{ color: "#2c4964" }}>Our Doctors For {capitalizedKey}</h2>
                                </div>
                                <div className="row justify-content-center">

                                    {filteredDoctors.map((doctor) => (

                                        <div key={doctor.id} className="col-lg-4" style={{ marginBottom: "25px" }}>
                                            <DoctorCard qualifications={doctor.qualifications} cc={doctor.coreCompetencies} summary={doctor.summary} lang={doctor.languages} name={doctor.name} image={doctor.image} speciality={doctor.speciality} option={doctor.option} nat={doctor.nationality} onComponentChange={onComponentChange} check={true} />
                                        </div>
                                    ))}


                                </div>
                            </div>
                        </section>
                    )}
                </div>
            </section>
        </>
    );


}
export default SpecialitiesPage