import '../../index.css';
import Card from 'react-bootstrap/Card';
import { GiPassport } from "react-icons/gi";
import { FaCar } from "react-icons/fa";
import { FaPlane } from "react-icons/fa";
import { FaHotel } from "react-icons/fa";
import React, { useEffect, useState, useRef } from 'react';
import Appointment from '../Appointment/Appointment';
import SuccessModal from '../SuccessModal/SuccessModal';
import { FaUserDoctor } from "react-icons/fa6";
import { GiHospital } from "react-icons/gi";
import { TbTargetArrow } from "react-icons/tb";
import { FaHandshake } from "react-icons/fa";
import { Helmet } from 'react-helmet';
import tourismBanner from './medicalTourism.jpg'; // Ensure the path is correct

function Tourism() {

    const [showAppointment, setShowAppointment] = useState(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);

    const handleCloseAppointment = () => setShowAppointment(false);
    const handleShowAppointment = () => setShowAppointment(true);
    function DisplayPopUp() {
        console.log("test")
        setShowSuccessMessage(true)
    }
    function closePopUp() {
        setShowSuccessMessage(false)
    }

    return (
        <>
        <Helmet>
        <title>Medical Tourism in Dubai – Your Path to World-Class Care at AACSH</title>
        <meta name="description" content="Discover world-class cosmetic and medical treatments in the heart of Dubai at the American Academy of Cosmetic Surgery Hospital (AACSH). We provide tailored packages in plastic surgery, hair transplants, and weight loss procedures, all designed to enhance your beauty and well-being." />
        {/* Add more meta tags if needed */}
      </Helmet>
        <section id="about" className="about">
        

            <Appointment show={showAppointment} handleClose={handleCloseAppointment} showPopUp={DisplayPopUp} />
            <SuccessModal show={showSuccessMessage} handleClose={closePopUp} />
            <div className="container-fluid">
                <div className="row" style={{ marginTop: "60px" }}>
                <img src={tourismBanner} alt="Weight Loss & Nutrition"  />

                    <div className="col-xl-12 col-lg-12 icon-boxes d-flex flex-column align-items-stretch py-8 px-lg-5">
                        <div className="section-title" >
                            <h2 style={{ color: "#2c4964", marginTop: "20px" }}>Medical Tourism</h2>
                        </div>
                        <p>Dubai is a world-renowned destination for medical tourism, offering state-of-the-art healthcare facilities combined with luxurious experiences. At the American Academy of Cosmetic Surgery Hospital (AACSH), we specialize in providing top-tier medical treatments with a focus on patient satisfaction, comfort, and safety. Whether you're seeking cosmetic surgery, hair transplants, or bariatric procedures, AACSH is here to help you achieve your goals in a world-class environment.</p>
                        <div className="col-xl-7 col-lg-6 icon-boxes d-flex flex-column align-items-stretch py-8 px-lg-5">
                            <h3 style={{ fontSize: "22px", marginTop: "30px" }}>
                                Why Choose AACSH for Medical Tourism?
                            </h3>
                            <div className="icon-box">
                                <div className="icon"><FaUserDoctor size={35}/></div>
                                <h4 className="title"><a style={{ textDecoration: "none" }} href="">World Class Surgeons</a></h4>
                                <p className="description">
                                    Our expert team of board-certified surgeons are internationally recognized for their skills and success in various medical fields.
                                </p>
                            </div>

                            <div className="icon-box">
                                <div className="icon">< GiHospital size={35}/></div>
                                <h4 className="title"><a style={{ textDecoration: "none" }} href="">State-of-the-Art Facilities</a></h4>
                                <p className="description">
                                AACSH is equipped with the latest technology and offers luxurious patient care, ensuring a comfortable and successful recovery                                </p>
                            </div>

                            <div className="icon-box">
                                <div className="icon">< TbTargetArrow size={35}/></div>
                                <h4 className="title"><a style={{ textDecoration: "none" }} href="">Tailored Treatment Plans</a></h4>
                                <p className="description">
                                Each patient receives a customized treatment plan based on individual needs and goals
                                </p>
                            </div>

                            <div className="icon-box">
                                <div className="icon"><FaHandshake size={35}/></div>
                                <h4 className="title"><a style={{ textDecoration: "none" }} href="">Seamless Patient Experience</a></h4>
                                <p className="description">
                                We handle every detail from consultation to recovery, including visa assistance, accommodation arrangements, and post-operative care.
                                    </p>
                            </div>


                            <h3 style={{ fontSize: "22px", marginTop: "30px" }}>
                                Our Medical Tourism Services
                            </h3>
                            <div className="icon-box">
                                <div className="icon" style={{fontSize: "30px"}}>1</div>
                                <h4 className="title"><a style={{ textDecoration: "none" }} href="">Free Online Consultation</a></h4>
                                <p className="description">
                                        Discuss your treatment goals with our experienced medical team from the comfort of your home.
                                        Understand the procedure, expected results, and customized treatment options before making any decisions.
                                   
                                </p>
                            </div>

                            <div className="icon-box">
                                <div className="icon" style={{fontSize: "30px"}}>2</div>
                                <h4 className="title"><a style={{ textDecoration: "none" }} href="">Comprehensive Care Packages</a></h4>
                                <p className="description">
                                        We provide a full medical tourism package that includes airport transfers, hotel accommodation, hospital stay, and personalized post-operative care.
                                        
                                </p>
                            </div>

                            <div className="icon-box">
                                <div className="icon" style={{fontSize: "30px"}}>3</div>
                                <h4 className="title"><a style={{ textDecoration: "none" }} href="">Translation Services</a></h4>
                                <p className="description">
                                            For international patients, we offer multilingual services to ensure effective communication throughout your treatment journey.
                                </p>
                            </div>

                            <div className="icon-box">
                                <div className="icon" style={{fontSize: "30px"}}>4</div>
                                <h4 className="title"><a style={{ textDecoration: "none" }} href="">Luxury Accommodation & VIP Services</a></h4>
                                <p className="description">
                                    Stay in one of Dubai’s top hotels, with our team assisting with luxury transportation and concierge services to make your stay comfortable and enjoyable.
                                </p>
                            </div>

                            <h3 style={{ fontSize: "22px", marginTop: "30px" }}>
                                Featured Treatment Packages
                            </h3>
                            <div className="icon-box">
                                <div className="icon" style={{fontSize: "30px"}}>1</div>
                                <h4 className="title"><a style={{ textDecoration: "none" }} href="">Plastic & Cosmetic Surgery Package</a></h4>
                                <p className="description">
                                    Transform your look with AACSH’s highly sought-after cosmetic procedures. Our world-renowned plastic surgeons offer:                                </p>
                                <p className="description">
                                    <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}> {/* Optional: Adjust padding as needed */}
                                        <li>
                                            <strong>Rhinoplasty (Nose Surgery):</strong> Reshape and enhance your nose for a balanced facial profile.
                                        </li>
                                        <li>
                                            <strong>Facelift:</strong> Achieve a more youthful appearance with our advanced facelift techniques.
                                        </li>
                                        <li>
                                            <strong>Breast Augmentation:</strong> Enhance your body contours with safe and effective breast procedures.
                                        </li>
                                        <li>
                                            <strong>Liposuction & Body Contouring:</strong> Eliminate stubborn fat and sculpt your body to perfection.
                                        </li>
                                    </ul>
                                </p>

                                <p className="description" ><strong>Package Includes:</strong>
                                    <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}> {/* Optional: Adjust padding as needed */}
                                        <li>Consultation with top plastic surgeons.</li>
                                        <li>Pre-surgery medical tests.</li>
                                        <li>VIP hospital stay and nursing care.</li>
                                        <li>Post-surgery follow-up consultations.</li>
                                    </ul>
                                </p>
                            </div>

                            <div className="icon-box">
                                <div className="icon" style={{fontSize: "30px"}}>2</div>
                                <h4 className="title"><a style={{ textDecoration: "none" }} href="">Hair transplant Package</a></h4>
                                <p className="description">
                                    Regain your confidence with AACSH’s cutting-edge hair restoration procedures. We specialize in:
                                </p>
                                <p className="description">
                                    <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}> {/* Optional: Adjust padding as needed */}
                                        <li>
                                            <strong>FUE (Follicular Unit Extraction):</strong> A minimally invasive technique that gives natural-looking results with no visible scarring.
                                        </li>
                                        <li>
                                            <strong>PRP Therapy:</strong> Platelet-Rich Plasma therapy to boost hair growth and improve hair density.
                                        </li>
                                    </ul>
                                </p>

                                <p className="description" ><strong>Package Includes:</strong>
                                    <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}> {/* Optional: Adjust padding as needed */}
                                        <li>Personalized consultation and hair analysis.</li>
                                        <li>FUE Hair Transplant with experienced specialists.</li>
                                        <li>PRP sessions to enhance results.</li>
                                        <li>Complimentary aftercare products and follow-up consultations.</li>
                                    </ul>
                                </p>
                            </div>

                            <div className="icon-box">
                                <div className="icon" style={{fontSize: "30px"}}>3</div>
                                <h4 className="title"><a style={{ textDecoration: "none" }} href="">Bariatric Surgery Package</a></h4>
                                <p className="description">
                                    For individuals looking to achieve significant weight loss, AACSH offers life-changing bariatric procedures, including:
                                </p>
                                <p className="description">
                                    <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}> {/* Optional: Adjust padding as needed */}
                                        <li>
                                            <strong>Gastric Sleeve Surgery:</strong> A minimally invasive procedure that reduces the size of the stomach to promote weight loss.
                                        </li>
                                        <li>
                                            <strong>Gastric Bypass Surgery:</strong> A highly effective procedure to help manage obesity and related conditions such as diabetes.
                                        </li>
                                    </ul>
                                </p>

                                <p className="description" ><strong>Package Includes:</strong>
                                    <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}> {/* Optional: Adjust padding as needed */}
                                        <li>Comprehensive consultation with bariatric surgeons and nutritionists.</li>
                                        <li>Pre-surgery medical tests and assessments.</li>
                                        <li>Full post-surgery support, including dietary guidance and fitness plans.</li>
                                        <li>Luxury hospital stay and recovery monitoring.</li>
                                    </ul>
                                </p>
                            </div>

                            <h3 style={{ fontSize: "22px", marginTop: "30px" }}>
                                How to Start Your Medical Tourism Journey at AACSH
                            </h3>
                            <p className="description">
                                <ol style={{ paddingLeft: '20px' }}> {/* Optional: Adjust padding as needed */}
                                    <li>
                                        <strong>Get in Touch:</strong> Contact us via email or phone to schedule your free online consultation.
                                    </li>
                                    <li>
                                        <strong>Plan Your Treatment:</strong> Our team will guide you through the process, from your initial consultation to creating a customized treatment plan.
                                    </li>
                                    <li>
                                        <strong>Travel to Dubai:</strong> We assist with visa applications, flight bookings, and luxury accommodations for a seamless travel experience.
                                    </li>
                                    <li>
                                        <strong>Receive World-Class Care:</strong> Enjoy personalized medical treatment at AACSH, and recover in the comfort of our luxurious facilities.
                                    </li>
                                    <li>
                                        <strong>Post-Treatment Support:</strong> We provide continuous support after your procedure with virtual follow-ups and guidance for long-term care.
                                    </li>
                                </ol>
                            </p>

                        </div>
                        <div className="col-xl-12 col-lg-12 icon-boxes d-flex flex-column align-items-stretch py-8 px-lg-5" style={{ textAlign: 'center' }}>
                            <h3 style={{ fontSize: "22px", marginTop: "30px" }}>
                                Why Choose AACSH for Medical Tourism?
                            </h3>
                            <div className="row mt-4" style={{textAlign: "left"}}> {/* Optional: Adds margin-top for spacing */}
                                <div className="col-md-6 mb-4"> {/* Column for the first card */}
                                    <Card>
                                        <Card.Body>
                                            <GiPassport size={40} style={{ marginRight: "15px", color: "#69426f" }} />
                                            Visa Arrangements
                                        </Card.Body>
                                    </Card>
                                </div>
                                <div className="col-md-6 mb-4"> {/* Column for the second card */}
                                    <Card>
                                        <Card.Body>
                                            <FaCar size={40} style={{ marginRight: "15px", color: "#69426f" }} />
                                            Airport Pickup and Drop Off Service
                                        </Card.Body>
                                    </Card>
                                </div>
                                <div className="col-md-6 mb-4"> {/* Column for the third card */}
                                    <Card>
                                        <Card.Body>
                                            <FaPlane size={40} style={{ marginRight: "15px", color: "#69426f" }} />
                                            Travel Arrangements (if required)
                                        </Card.Body>
                                    </Card>
                                </div>
                                <div className="col-md-6 mb-4"> {/* Column for the fourth card */}
                                    <Card>
                                        <Card.Body>
                                            <FaHotel size={40} style={{ marginRight: "15px", color: "#69426f" }} />
                                            Choice of Accomodation
                                        </Card.Body>
                                    </Card>
                                </div>
                            </div>
                            <div style={{ marginTop: "50px", alignItems: "center", display: "flex", }}>
                                <a className="appointment-btn" onClick={handleShowAppointment} style={{ textDecoration: "none", marginRight: "auto", marginLeft: "auto" }} href="#appointment">Book Now</a>

                            </div>
                        </div>


                    </div>

                </div>
            </div>
        </section>
        </>
    )
}

export default Tourism